import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Col, Row, Select, Skeleton } from "antd";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/businessProposal/businessProposal.interface";
import {
  generationFields,
  setUpdateIsDisable,
  setUpdateFields,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { savePPTIndustry } from "../../../services/generation";
import { getFirstLevelOptions } from "../../../helper/GenerationHelper";
import { IMAGES, isEmpty } from "../../../shared";

interface IFunctions {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const IndustryFun = ({ localFields, setLocalFields }: IFunctions) => {
  const dispatch = useDispatch();
  const { isLoading, isDisable, pptGeneration, isPPTPullingLoading, isPPTDataLoading } =
    useSelector((state: RootState) => state.busProposalSlice);
  const { industryTaxonomy, functionTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const industryOptions = getFirstLevelOptions(industryTaxonomy?.taxonomyTree || []);
  const functionOptions = getFirstLevelOptions(functionTaxonomy?.taxonomyTree || []);
  const isLoadingOrDisable =
    isDisable?.industryFun ||
    isLoading?.industryFun ||
    isPPTPullingLoading ||
    pptGeneration?.taxonomy_node_id;

  const handleUpdateReduxState = () => {
    dispatch(setUpdateFields({ field: generationFields.industry, value: localFields?.industry }));
    dispatch(setUpdateFields({ field: generationFields.function, value: localFields?.function }));
  };

  const handleNext = async () => {
    if (localFields?.industry) {
      const res = await savePPTIndustry(localFields?.industry, pptGeneration?.business_proposal_id);

      if (!isEmpty(res)) {
        handleUpdateReduxState();
        dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
      }
    } else {
      toast.error("Please select industry");
    }
  };

  const handleSelectIndustry = (value: number) => {
    setLocalFields((field) => ({ ...field, industry: value }));
  };

  const handleSelectFunction = (value: number) => {
    setLocalFields((field) => ({ ...field, function: value }));
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">2</span>
          <h2>Selected Industry</h2>
        </div>

        <div className="card-content">
          {isPPTDataLoading ? (
            <Skeleton.Input className="skeleton-input" active={true} />
          ) : (
            <div className="selects-wrap">
              <Row gutter={30}>
                <Col span={4}>
                  <div className="asset-type-select">
                    <label>Industry</label>
                    <Select
                      maxTagCount={1}
                      popupClassName="ui-dropdown-default"
                      placeholder="Choose Industry"
                      value={localFields?.industry}
                      onChange={handleSelectIndustry}
                      disabled={isLoadingOrDisable}
                      options={industryOptions}
                      showSearch
                      filterOption={(inputValue: string, option: any) =>
                        option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                      }
                    />
                  </div>
                </Col>

                <div hidden={true}>
                  <Col span={4}>
                    <div className="asset-type-select">
                      <label>Function</label>
                      <Select
                        mode="multiple"
                        maxTagCount={1}
                        popupClassName="ui-dropdown-default"
                        placeholder="Choose Function"
                        value={localFields?.function}
                        onChange={handleSelectFunction}
                        disabled={isLoadingOrDisable}
                        options={functionOptions}
                        showSearch
                        filterOption={(inputValue: string, option: any) =>
                          option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                      />
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          )}
        </div>

        {!isPPTDataLoading && (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {isLoading?.industryFun && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}

              <Button
                type="primary"
                className="outline-btn"
                disabled={isLoadingOrDisable}
                onClick={handleNext}>
                Next
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default IndustryFun;
