import { NavigateFunction } from "react-router-dom";
import { store } from "../store";
import { ILogin } from "../shared";
import { post } from "./apiClients";
import { toast } from "react-toastify";
import { PATHS, LocalStorage } from "../shared";
import {
  setIsCreatePasswordLoading,
  setIsSendOtpLoading,
  setIsVerifyOtpLoading,
  setLoginLoading,
  setRestPasswordLoading,
  setUserId,
} from "../store/auth/auth.slice";
import { persistStore } from "redux-persist";
import { removeLocalstorage } from "../shared";
import { getAllChatHistory } from "./conversationalSearch";
import { setAllFileTypesSelected } from "../store/filtersFromTaxonomyData/filters.slice";
import { fileType } from "../components/sideBar/FileTypeData";

export const authenticatesUser = async (request: ILogin, navigate: NavigateFunction) => {
  store.dispatch(setLoginLoading(true));

  try {
    const { data } = await post("/core/token/authenticate", request);
    const user = {
      id: data.id,
      username: data.username,
      name: data.name,
      email: data.email,
      userRole:
        data.userPermissions && data.userPermissions.length ? data.userPermissions[0].role : null,
    };
    localStorage.setItem(LocalStorage.User, JSON.stringify(user));
    localStorage.setItem(LocalStorage.Token, data?.jwttoken);

    store.dispatch(setUserId(data?.id));

    navigate(PATHS.home);
    getAllChatHistory({ userId: data?.id, configSettingId: 1 });
    store.dispatch(setAllFileTypesSelected(fileType));

    toast.success("Login successfully");
  } catch (error: any) {
    toast.error(error?.errorMessage || "Failed to login");
  } finally {
    store.dispatch(setLoginLoading(false));
  }
};

export const sendOtp = async (request: { email: string }, navigate: NavigateFunction) => {
  try {
    store.dispatch(setIsSendOtpLoading(true));
    await post(`/core/token/send-otp`, request);
    navigate(PATHS.verifyOtp);
    toast.success("OTP sent successfully");
  } catch (error: any) {
    toast.error("User does not exist with this email id");
  } finally {
    store.dispatch(setIsSendOtpLoading(false));
  }
};

export const verifyOtp = async (
  request: { email: string; otp: number },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsVerifyOtpLoading(true));
    await post(`/core/token/verify-otp`, request);
    navigate(PATHS.changePassword);
    toast.success("OTP verified successfully");
  } catch (error: any) {
    console.log(error, "verifyOtp api error");
    toast.error(error ?? "Failed to verify OTP", error);
  } finally {
    store.dispatch(setIsVerifyOtpLoading(false));
  }
};

export const CreateNewPassword = async (
  request: { email: string; otp: number; newPassword: string },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsCreatePasswordLoading(true));
    await post(`/core/token/createNewPassword`, request);
    navigate(PATHS.login);
    toast.success("Password updated successfully");
  } catch (error: any) {
    console.log(error, "CreateNewPassword api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setIsCreatePasswordLoading(false));
  }
};

export const resetPassword = async (
  request: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    userId: number;
  },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setRestPasswordLoading(true));
    await post(`/core/token/resetPasswordOnboarding`, request);
    persistStore(store).purge();
    removeLocalstorage();
    toast.success("Password reset successfully");
    navigate(PATHS.login);
  } catch (error: any) {
    console.log(error, "reset api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setRestPasswordLoading(false));
  }
};
