import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  IAgenda,
  IBusProposalSliceProps,
  ICompanyDetails,
  IFields,
  IIsDisable,
  IIsLoading,
  IIsShow,
  IPPTGeneration,
} from "./businessProposal.interface";
import { AGENDA_OPT, getDefaultSelectedAgendaId } from "../../../helper/GenerationHelper";

export const generationFields = {
  topic: "topic",
  industryFun: "industryFun",
  industry: "industry",
  function: "function",
  agenda: "agenda",
  companyDetails: "companyDetails",
};

const isShowInitValue: IIsShow = {
  topic: true,
  industryFun: false,
  agenda: false,
  companyDetails: false,
};

const isDisableInitValue: IIsDisable = {
  topic: false,
  industryFun: false,
  agenda: false,
  companyDetails: false,
};

const isLoadingInitValue: IIsLoading = {
  topic: false,
  industryFun: false,
  agenda: false,
  companyDetails: false,
};

export const fieldsInitValue: IFields = {
  topic: "",
  industry: null,
  function: null,
  agenda: getDefaultSelectedAgendaId() ? [getDefaultSelectedAgendaId()] : [],
  companyDetails: {
    name: "",
    docName: "",
    document: null,
  },
};

export const pptGenerationInitValue: IPPTGeneration = {
  business_proposal_id: null,
  user_id: -1,
  prompt: "",
  taxonomy_node_id: null,
  agendas: [],
  company_name: "",
  file_name: "",
  original_file_name: "",
  bucket_name: "",
  status: "",
  pre_signed_url: "",
  error_detail: "",
  data: "",
  document_pre_signed_url: "",
  document_name: "",
};

const initialState: IBusProposalSliceProps = {
  isShow: isShowInitValue,
  isDisable: isDisableInitValue,
  isLoading: isLoadingInitValue,
  fields: fieldsInitValue,
  pptGeneration: pptGenerationInitValue,
  isPPTDataLoading: false,
  isPPTPullingLoading: false,
  isDocDownloading: false,
  agendaOpt: AGENDA_OPT,
};

export const busProposalSlice = createSlice({
  name: "busProposalSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setUpdateIsShow: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isShow = {
        ...state.isShow,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsShow: (state) => {
      state.isShow = isShowInitValue;
    },

    setUpdateIsDisable: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isDisable = {
        ...state.isDisable,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsDisable: (state) => {
      state.isDisable = isDisableInitValue;
    },

    setUpdateIsLoading: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isLoading = {
        ...state.isLoading,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsLoading: (state) => {
      state.isLoading = isLoadingInitValue;
    },

    setUpdateFields: (
      state,
      action: PayloadAction<{
        field: string;
        value: string | React.Key[] | any[] | number | null | ICompanyDetails;
      }>
    ) => {
      state.fields = {
        ...state.fields,
        [action.payload.field]: action.payload.value,
      };
    },
    clearFields: (state) => {
      state.fields = fieldsInitValue;
    },

    clearAllDataBusProp: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = fieldsInitValue;
      state.isPPTDataLoading = false;
      state.isPPTPullingLoading = false;
      state.isDocDownloading = false;
    },
    clearDataOnTopicStart: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = { ...fieldsInitValue, topic: state?.fields?.topic };
      state.agendaOpt = AGENDA_OPT;
    },

    setPPTGeneration: (state, action: PayloadAction<IPPTGeneration>) => {
      state.pptGeneration = action.payload;
    },
    setIsPPTDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isPPTDataLoading = action.payload;
    },
    setIsPPTPullingLoading: (state, action: PayloadAction<boolean>) => {
      state.isPPTPullingLoading = action.payload;
    },
    setIsDocDownloading: (state, action: PayloadAction<boolean>) => {
      state.isDocDownloading = action.payload;
    },
    setAgendaOpt: (state, action: PayloadAction<IAgenda[]>) => {
      state.agendaOpt = action.payload;
    },
  },
});

export const {
  setUpdateIsShow,
  clearIsShow,
  setUpdateIsDisable,
  clearIsDisable,
  setUpdateIsLoading,
  clearIsLoading,
  setUpdateFields,
  clearFields,
  clearAllDataBusProp,
  clearDataOnTopicStart,
  setPPTGeneration,
  setIsPPTDataLoading,
  setIsPPTPullingLoading,
  setIsDocDownloading,
  setAgendaOpt,
} = busProposalSlice.actions;

export default busProposalSlice.reducer;
