import { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table, Tooltip } from "antd";

import SkeletonTable from "./SkeletonTable";
import { RootState } from "../../../store";
import {
  IFields,
  IFunctionsTableData,
  IProcessTableData,
} from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { fetchKpiSubProcessByProcess } from "../../../services/generation";
import { IMAGES } from "../../../shared";
import { getFormattedProcess, getIsProcessing } from "../../../helper/GenerationHelper";

interface IKpiProcess {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const KpiProcess = ({ localFields, setLocalFields }: IKpiProcess) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, kpiGenerations, isAllLoading, isKpiDataLoading, fields } =
    useSelector((state: RootState) => state.kpiGeneration);
  const loading = isLoading?.process || isAllLoading;

  const handleSelectProcess = (
    _: React.Key[],
    selectedRows: IProcessTableData[],
    selectedFunction: string
  ) => {
    let existingSelectedProcess = localFields?.process || [];
    existingSelectedProcess = existingSelectedProcess.filter(
      (process) => process.function !== selectedFunction
    );

    const updatedSelectedProcess = [...existingSelectedProcess, ...selectedRows];
    const finalSelectedProcess = Array.from(
      new Set(updatedSelectedProcess.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));

    setLocalFields((field) => ({ ...field, process: finalSelectedProcess }));
  };

  const handleProcessNext = async () => {
    if (kpiGenerations?.kpi_id && localFields?.process && localFields?.process?.length) {
      const processIds = localFields?.process?.map((item) => item.id) || [];
      const res = await fetchKpiSubProcessByProcess(kpiGenerations?.kpi_id, processIds);

      if (res) {
        dispatch(setUpdateFields({ field: generationFields.process, value: localFields?.process }));
        dispatch(setUpdateIsShow({ field: generationFields.subProcess, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.process, value: true }));
      }
    } else {
      toast.error("Please select process");
    }
  };

  const getSelectedFunction = () => {
    const functions = (localFields?.functions || []).map((item) => item.function);
    const formattedProcess = getFormattedProcess(kpiGenerations?.processes || []).map(
      (item) => item.function
    );

    const filteredFun = functions.filter((item) => formattedProcess.includes(item));
    const finalFunctions = (localFields?.functions || []).filter((item) =>
      filteredFun.includes(item.function)
    );

    return finalFunctions;
  };

  const getTableDataLength = () => {
    let tableDataLengthArr: any[] = [];

    const selectedFunction = getSelectedFunction() || [];
    selectedFunction.map((fun: IFunctionsTableData) => {
      const formattedProcess = getFormattedProcess(kpiGenerations?.processes || []) || [];

      const tableData: any[] = formattedProcess
        .filter((process: IProcessTableData) => process?.function === fun.function)
        .map((process: IProcessTableData, processIdx: number) => ({
          ...process,
          index: processIdx + 1,
        }));
      tableDataLengthArr.push(tableData.length);
    });

    return tableDataLengthArr;
  };

  const renderProcessTable = () => {
    const selectedFunction = getSelectedFunction() || [];
    const tableDataLengthArr = getTableDataLength() || [];

    return (
      <>
        {selectedFunction && selectedFunction.length ? (
          selectedFunction.map((fun: IFunctionsTableData, funIndex: number) => {
            const formattedProcess = getFormattedProcess(kpiGenerations?.processes || []) || [];

            let tableData = formattedProcess
              .filter((process: IProcessTableData) => process?.function === fun.function)
              .map((process: IProcessTableData, processIdx: number) => ({
                ...process,
                index: processIdx + 1,
              }));
            // const processKeys =
            // localFields?.process && localFields?.process?.length
            //   ? localFields?.process
            //       ?.filter((item) => item?.function === fun.function)
            //       .map((item) => item.key)
            //   : [];
            const isEdit = !isDisable?.process;
            let processKeys: any[] = [];

            if (isEdit) {
              processKeys =
                localFields?.process && localFields?.process?.length
                  ? localFields?.process
                      ?.filter((item) => item?.function === fun.function)
                      .map((item) => item.key)
                  : [];
            } else {
              const selectedProcessIds: any[] = fields?.process?.map((item) => item.id);
              const filteredProcess =
                formattedProcess && formattedProcess?.length
                  ? formattedProcess?.filter((item) => item?.function === fun.function)
                  : [];

              const result: any = [];
              let additionalIndexLength = 0;

              if (funIndex > 0 && tableDataLengthArr?.length >= funIndex) {
                for (let i = 0; i < funIndex; i++) {
                  additionalIndexLength += tableDataLengthArr[i] || 0;
                }
              }

              if (filteredProcess && filteredProcess?.length) {
                filteredProcess.forEach((item, index) => {
                  if (selectedProcessIds.includes(item.id)) {
                    result.push({ ...item, index: index + 1 + additionalIndexLength });
                  }
                });
              }

              tableData = tableData.map((item, index) => ({
                ...item,
                key: index + 1 + additionalIndexLength,
              }));
              processKeys = result && result?.length ? result.map((item: any) => item?.index) : [];
            }

            if (tableData && tableData.length) {
              return (
                <div key={funIndex} className={`table-style ${funIndex !== 0 && "mt-7"}`}>
                  <Table
                    key={funIndex}
                    rowSelection={{
                      selectedRowKeys: processKeys || [],
                      onChange: (keys, selectedRows) =>
                        handleSelectProcess(keys, selectedRows, fun.function),
                      getCheckboxProps: () => {
                        return {
                          disabled: isDisable?.process || loading,
                        };
                      },
                    }}
                    columns={[
                      {
                        title: "No.",
                        dataIndex: "index",
                        rowScope: "row",
                        width: "30px",
                      },
                      {
                        title: `Select the process for "${fun.function}"`,
                        dataIndex: "name",
                      },
                    ]}
                    dataSource={tableData}
                    pagination={false}
                  />
                </div>
              );
            } else {
              return <></>;
            }
          })
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">3</span>
          <h2>
            Selected process(s)
            <Tooltip
              title="For the selected processes, all associated subprocesses will be shown."
              color="#fff"
              placement="topLeft"
              overlayClassName="tooltip-text tooltip-ui-new">
              <i className="ri-information-line cursor-pointer"></i>
            </Tooltip>
          </h2>
        </div>

        <div className="card-content">
          {isKpiDataLoading ? (
            <SkeletonTable />
          ) : kpiGenerations?.processes && kpiGenerations?.processes?.length ? (
            renderProcessTable()
          ) : (
            <Table
              columns={[
                {
                  title: "No.",
                  dataIndex: "index",
                  rowScope: "row",
                  width: "30px",
                },
                {
                  title: `Select the process`,
                  dataIndex: "name",
                },
              ]}
              dataSource={[]}
              pagination={false}
            />
          )}
        </div>

        {!isKpiDataLoading && kpiGenerations?.processes && kpiGenerations?.processes?.length ? (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {(isLoading?.process || getIsProcessing(kpiGenerations?.sub_process_status)) && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                onClick={handleProcessNext}
                disabled={isDisable?.process || loading}>
                Next
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
};

export default KpiProcess;
