export enum PATHS {
  home = "/",
  login = "/login",
  about = "/about",
  admin = "/admin",
  verifyOtp = "/verify-otp",
  adminLogs = "/admin/logs",
  resetPassword = "/reset-password",
  forgotPassword = "/forgot-password",
  changePassword = "/change-password",
  browseCategory = "/browse-project/browse-content",
  adminUserManagement = "/admin/user-management",
  conversationalSearch = "/conversational-search",
  manageTaxonomyTags = "/admin/taxonomy-manage-tags",
  adminContentManagement = "/admin/content-management",
  browseProject = "/browse-project",
  generation = "/generation",
  generateKPIs = "/generation/KPIs",
  generateBusinessProposal = "/generation/business-proposal",
  adminTaxonomyManagement = "/admin/taxonomy-management",
  adminTaxonomyOverview = "/admin/taxonomy-management-overview",
  viewEditContentManagement = "/admin/view-edit-content-management",
  viewContentManagementByUser = "/view-edit-content-management",
}

export enum TaxonomyView {
  FlatKeywords = "FlatKeywords",
  TreeHierarchy = "TreeHierarchy",
}

export enum TaxonomyType {
  FLAT = "FLAT",
  TREE = "TREE",
}

export enum ContentManage {
  InitSummaryLength = 42,
  InitTagsLength = 2,
}

export enum Month {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12,
}

export enum TaxonomyTitle {
  Industry = "industry",
  Function = "function",
  AssetType = "asset type",
  ReferenceKeywords = "reference keywords",
}

export enum ShowTaxonomyLimit {
  Industry = 1,
  Function = 1,
}

export enum TaxonomyDeleteOptions {
  MoveAllChildToParent = 1,
  DeleteAllChildren = 2,
  ReplaceText = 3,
}

export enum UserRoles {
  Admin = "ADMIN",
  ContentEditor = "CONTENT_EDITOR",
  User = "USER",
}

export enum FromWhere {
  BrowseCategory = "browse-category",
  ConversationalSearch = "conversational-search",
  viewEditContentManagement = "view-edit-content-management",
  Home = "home",
  KpiGeneration = "kpi-genearation",
}

export enum ContantManagementStatus {
  Processing = "PROCESSING",
  Failed = "FAILED",
  Completed = "LOADED_INTO_LLM",
  SentToLlm = "SENT_TO_LLM",
}

// Conversational Search Start
export enum Model {
  GPT3 = "gpt-3.5-turbo",
  GPT4 = "gpt-4",
  LLAMA = "llama",
}

export enum userType {
  AI = "ai",
  HUMAN = "human",
}

export enum ChatActions {
  Like = "Like",
  Dislike = "Dislike",
}

// Conversational Search End
export enum AssetUploadStatus {
  FAILED = "FAILED",
  RECEIVED = "RECEIVED",
  PROCESSING = "PROCESSING",
  SENT_TO_LLM = "SENT_TO_LLM",
  FAILED_IN_LLM = "FAILED_IN_LLM",
  DELETING_START = "DELETING_START",
  UPLOADED_TO_DO = "UPLOADED_TO_DO",
  LOADED_INTO_LLM = "LOADED_INTO_LLM",
  CONVERTED_TO_PDF = "CONVERTED_TO_PDF",
  COMPLETED = "COMPLETED",
  DELETED = "DELETED",
  NOT_STARTED = "NOT_STARTED",
  STOPPING = "STOPPING",
  STOPPED = "STOPPED",
}

export enum PageLimit {
  UserManagement = 50, // set 50
  Taxonomy = 10, // set 10
  ContentManagement = 20, // set 20
  ConversationalSearch = 20,
}

export enum PageNumber {
  ConversationalSearch = 0,
}

export const PublishedOption = [
  { label: "All", value: "ALL" },
  { label: "Published ", value: "TRUE" },
  { label: "Draft ", value: "FALSE" },
];

export enum RouteParams {
  UploadAsset = "upload-asset",
}

export enum FileName {
  AssetSCV = "Assets.csv",
}

export const Images = ["png", "jpg", "jpeg", "svg"];

export const NeedHelpEmail = "support@accenture.com";

export const RunVisionFileType = ["txt", "pdf", "doc", "docx", "ppt", "pptx"];

export enum LocalStorage {
  User = "user_acnkm",
  Token = "token_acnkm",
}

export enum SummaryStaus {
  NOT_STARTED = "NOT_STARTED",
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  NULL = "NULL",
  CANCELLED = "CANCELLED",
  TO_BE_PROCESSED = "TO_BE_PROCESSED",
}
