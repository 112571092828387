import { Button, Input, Skeleton, Space } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  ChatActions,
  IMAGES,
  LocalStorage,
  Model,
  convertToNumber,
  getLocalStorageItem,
  getUserId,
  uniqueId,
  userType,
} from "../../../shared";
import { IMessages } from "../../../store/conversationalSearch/conversationalSearch.interface";
import {
  addNewChatHistory,
  addNewQuestionAnswer,
  setChatAction,
  setIsStreamingStart,
  setLoadingMessageId,
  setNewChatHistoryId,
  setNewChatHistoryTitle,
  setSelectedChatHistorytoTop,
  setUserQuestion,
  updateAIAnswer,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import configs from "../../../shared/config";
import { messageComment, saveStreamChatReply } from "../../../services/conversationalSearch";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import UserComments from "../userComment/UserComments";
import RelevanAssets from "../assets/RelevantAssets";
import { useChats } from "../../../shared/hooks/useChat";
import { ArrowDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import OtherAssets from "../assets/OtherAssets";
import Markdown from "react-markdown";
import { findDescendantIds } from "../../../helper/TaxonomyHelper";
import { ITaxonomyTree } from "../../../store/taxonomy/taxonomy.interface";

let chatHistoryId: number = -1;
let isNewChat: boolean = false;
interface IChat {}

const Chat = ({}: IChat) => {
  const wsRef = useRef<WebSocket | null>(null);
  const dispatch = useDispatch();
  const chatContainerRef: any = useRef(null);
  const { getSelectedFilterObject } = useChats();
  const { selectedChatHistory, isStreamingStart, isNetworkOpen, loadingMessageId, userQuestion } =
    useSelector((state: RootState) => state.conversationalSearch);
  const [isShowExpandBtn, setIsShowExpandBtn] = useState(false);
  const { id, messages } = selectedChatHistory;
  const [isExpand, setIsExpand] = useState(true);
  const [question, setQuestion] = useState("");
  const [isSaveChatReply, setIsSaveChatReply] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<IMessages | undefined>(undefined);
  const [isRetryBtnClick, setIsRetryBtnClick] = useState(false);
  const { industryTaxonomy, functionTaxonomy, assetTypeTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );
  const [showScrollButton, setShowScrollButton] = useState(false);
  const {
    selectedAssetType,
    selectedFileType,
    selectedFunctions,
    selectedIndustry,
    selectedDateRange,
  } = useSelector((state: RootState) => state.filtersData);
  useEffect(() => {
    if (userQuestion) {
      setQuestion(userQuestion);
    }
  }, [userQuestion]);

  useEffect(() => {
    if (userQuestion && question) {
      dispatch(setUserQuestion(""));
      startNewChat();
    }
  }, [userQuestion, question]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const hasScrollBar = chatContainer.scrollHeight > chatContainer.clientHeight;
      setIsShowExpandBtn(hasScrollBar);
    }
  }, [messages]);

  const handleExpandOrCollapse = () => {
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    if (!isNetworkOpen && isStreamingStart) {
      if (wsRef.current) {
        wsRef.current.close(1000, "NETWORK_CLOSE");
        setErrorMessage(true);
        dispatch(setIsStreamingStart(false));
      }
    }
  }, [isNetworkOpen]);

  const connectWebSocket = (callback?: () => void) => {
    const token = getLocalStorageItem(LocalStorage.Token);
    let newWs = new WebSocket(`${configs.WEB_SOCKET_URL}rag/rag-with-metadata?token=${token}`);

    newWs.onopen = () => {
      wsRef.current = newWs;
      if (typeof callback === "function") {
        callback();
      }
    };

    let currentType = "";
    let currentContent = "";
    newWs.onmessage = (event: any) => {
      if (event?.data) {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case "start":
            if (data.content_type === null) {
              currentType = "stream";
              currentContent = "";
              dispatch(setIsStreamingStart(true));
            } else if (
              data.content_type === "optimised_question" ||
              data.content_type === "answer"
            ) {
              currentType = data.content_type;
            }
            break;

          case "stream":
            if (data.content_type === null) {
              if (currentType === "optimised_question") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "optimised_Question",
                    ans: currentContent,
                  })
                );
              } else if (currentType === "answer") {
                currentContent += data.message;
                dispatch(
                  updateAIAnswer({
                    chatId: id !== -1 ? id : chatHistoryId,
                    type: "content",
                    ans: currentContent,
                  })
                );
              }
            }
            break;

          case "end":
            if (data.content_type === "optimised_question") {
              currentContent = "";
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "isShowGeneratingLabel",
                  ans: true,
                })
              );
            } else if (data.content_type === "answer") {
              currentContent = "";
            } else {
              dispatch(setIsStreamingStart(false));
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "answerId",
                  ans: "m_" + Date.now(),
                })
              );
              dispatch(setLoadingMessageId(-1));
              setIsSaveChatReply(true);
            }
            break;

          case "blob":
            if (data.content_type === "citations") {
              let citations = JSON.parse(data.message);
              dispatch(
                updateAIAnswer({
                  chatId: id !== -1 ? id : chatHistoryId,
                  type: "citations",
                  ans: citations,
                })
              );
            }
            break;

          case "error":
            dispatch(setIsStreamingStart(false));
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "error",
                ans: true,
              })
            );
            dispatch(
              updateAIAnswer({
                chatId: id !== -1 ? id : chatHistoryId,
                type: "content",
                ans: data.message,
              })
            );
            break;

          default:
            // Handle unknown message types
            break;
        }
      }
    };

    newWs.onerror = (event) => {
      console.error("WebSocket error:", event);
    };

    newWs.onclose = (event) => {
      // newWs?.close();
      wsRef.current = null;
      if (event?.reason === "NETWORK_CLOSE") {
        setErrorMessage(true);
        dispatch(setIsStreamingStart(false));
      }
    };
  };

  useEffect(() => {
    if (isSaveChatReply) {
      SaveAIStreamReply();
      setIsSaveChatReply(false);
    }
  }, [isSaveChatReply]);

  const SaveAIStreamReply = async () => {
    const request = {
      id: isNewChat ? null : selectedChatHistory?.id,
      configSettingId: 1,
      title: isNewChat ? null : selectedChatHistory.title,
      messages: messages.slice(-2),
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      message: isNewChat ? messages[0]?.content : null,
      createdOn: selectedChatHistory.createdOn,
    };
    if (isNewChat) {
      Object.assign(request, {
        chatTaxonomies: [getSelectedFilterObject()],
      });
    } else {
      Object.assign(request, { chatTaxonomies: selectedChatHistory.chatTaxonomies });
    }

    try {
      const response = await saveStreamChatReply(request);
      if (response && response.status === 200 && isNewChat) {
        const { id, title } = response.data;
        dispatch(setNewChatHistoryId({ oldId: chatHistoryId, newId: id }));
        dispatch(setNewChatHistoryTitle({ chatHistoryId: id, title: title }));
      }
    } catch (error) {
      console.log("Error while saving AI stream reply", error);
    }
    dispatch(setIsStreamingStart(false));
    isNewChat = false;
  };

  const handleAskFurther = () => {
    if (id !== -1) {
      handleOldChat();
    } else {
      startNewChat();
    }
  };

  const handleOldChat = () => {
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
    };
    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };
    setQuestion("");
    dispatch(addNewQuestionAnswer({ chatId: id, queAns: [userMessage, aiReply] }));
    dispatch(setSelectedChatHistorytoTop(selectedChatHistory.id));
    connectWebSocket(() => {
      askToWebSocket();
    });
  };

  const startNewChat = () => {
    isNewChat = true;
    const userMessage: IMessages = {
      id: uniqueId(),
      type: userType.HUMAN,
      content: question.trim(),
      citations: [],
      error: false,
      answerId: "",
      thumbsUp: false,
      thumbsDown: false,
      userComments: "",
      optimised_Question: "",
      isStopGenerating: "FALSE",
      isShowGeneratingLabel: false,
    };
    const aiReply: IMessages = {
      ...userMessage,
      id: uniqueId(),
      type: userType.AI,
      content: "",
    };
    const newChatHistory = {
      id: uniqueId(),
      title: "Greetings",
      messages: [userMessage, aiReply],
      userId: getUserId(),
      modelName: Model.GPT3,
      predicted_next_questions: [],
      createdOn: dayjs().toISOString(),
      chatTaxonomies: [getSelectedFilterObject()],
      modifiedOn: dayjs().toISOString(),
    };
    chatHistoryId = newChatHistory.id;
    dispatch(addNewChatHistory(newChatHistory));
    dispatch(setLoadingMessageId(aiReply.id));
    connectWebSocket(() => {
      askToWebSocket();
    });
  };

  const getFilteredLeafNodes = (taxonomyData: ITaxonomyTree[], ids: number[]) => {
    let leafIds: number[] = [];

    if (ids && ids?.length) {
      ids.forEach((id) => {
        const descendantIds = findDescendantIds(taxonomyData, id);
        if (!(descendantIds && descendantIds?.length)) {
          leafIds.push(id);
        }
      });
    }
    return leafIds;
  };

  const askToWebSocket = () => {
    const taxonomy_filter = {
      tags: [
        {
          taxonomyId: functionTaxonomy.taxonomyId,
          taxonomyNodeIds: getFilteredLeafNodes(
            functionTaxonomy?.taxonomyTree,
            convertToNumber(selectedFunctions)
          ),
        },
        {
          taxonomyId: industryTaxonomy?.taxonomyId,
          taxonomyNodeIds: getFilteredLeafNodes(
            industryTaxonomy?.taxonomyTree,
            convertToNumber(selectedIndustry)
          ),
        },
        {
          taxonomyId: assetTypeTaxonomy?.taxonomyId,
          taxonomyNodeIds: getFilteredLeafNodes(
            assetTypeTaxonomy?.taxonomyTree,
            convertToNumber(selectedAssetType)
          ),
        },
      ],
      fileType: selectedFileType,
      startDate: selectedDateRange.length > 0 ? selectedDateRange[0] : null,
      endDate: selectedDateRange.length > 0 ? selectedDateRange[1] : null,
      is_bookmarked: null,
      is_owner: null,
    };

    if (wsRef.current) {
      const request = {
        question: isRetryBtnClick ? messages[messages.length - 2].content : question.trim(),
        chat_context: selectedChatHistory.messages.slice(
          0,
          isRetryBtnClick ? messages?.length - 2 : messages?.length
        ),
        taxonomy_filter: taxonomy_filter,
      };

      setQuestion("");
      setIsRetryBtnClick(false);
      wsRef.current.send(JSON.stringify(request));
    } else {
      console.error("WebSocket connection is not available.");
    }
  };

  const setErrorMessage = (isError: boolean = false) => {
    // setIsRetryBtnClick(false);

    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "error",
        ans: isError,
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "content",
        ans: isError ? "Connection is closed. Please try again" : "",
      })
    );
  };

  const handleCopyClick = async (value: string) => {
    try {
      await navigator.clipboard.writeText(value);
      toast.success("Text copied successfully");
    } catch (error) {
      console.error("Error copying text:", error);
    }
  };

  // Message Like Dislike
  const handleMessageActions = async (message: IMessages, action: string) => {
    try {
      let thumbsUp = false,
        thumbsDown = false;
      if (action === ChatActions.Like) {
        thumbsUp = !message.thumbsUp;
        thumbsDown = false;
      } else {
        thumbsDown = !message.thumbsDown;
        thumbsUp = false;
      }
      dispatch(
        setChatAction({
          ...message,
          userComments: "",
          thumbsUp: thumbsUp,
          thumbsDown: thumbsDown,
        })
      );

      if (action === ChatActions.Dislike && thumbsDown) {
        setIsComment(true);
      }
      if (thumbsUp) toast.success("Feedback sent.");

      const request = {
        chatId: selectedChatHistory.id,
        answerId: message.answerId,
        userComments: "",
        thumbsUp: thumbsUp,
        thumbsDown: thumbsDown,
      };

      const response = await messageComment(request);
      if (response && response.status === 200) {
      }
    } catch (error) {
      console.log("Error while message action", error);
    }
  };

  const handleStopGenerate = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "answerId",
        ans: "m_" + Date.now(),
      })
    );
    dispatch(
      updateAIAnswer({
        chatId: id !== -1 ? id : chatHistoryId,
        type: "isStopGenerating",
        ans: "TRUE",
      })
    );
    dispatch(setLoadingMessageId(-1));
    dispatch(setIsStreamingStart(false));
    setIsSaveChatReply(true);
  };

  const handleRetryBtn = () => {
    if (isNetworkOpen) {
      setIsRetryBtnClick(true);
      setErrorMessage();
      connectWebSocket(() => {
        askToWebSocket();
      });
    }
  };

  const isDisableChat = () => {
    return (
      !isNetworkOpen ||
      isStreamingStart ||
      (selectedChatHistory.id !== -1 && loadingMessageId === selectedChatHistory.id)
    );
  };

  //scrollToBottom
  useEffect(() => {
    if (messages?.length > 0) {
      scrollToBottom();
    }
  }, []);

  const isAtBottom = (container: HTMLDivElement): boolean => {
    const threshold = 1; // Adjust this threshold if necessary
    const scrollTop = Math.round(container.scrollTop);
    const scrollHeight = Math.round(container.scrollHeight);
    const clientHeight = Math.round(container.clientHeight);
    const atBottom = scrollHeight - scrollTop <= clientHeight + threshold;
    return atBottom;
  };

  const scrollToBottom = () => {
    if (chatContainerRef?.current) {
      chatContainerRef.current.scrollTop = chatContainerRef?.current.scrollHeight;
      setShowScrollButton(false);
    }
  };

  const handleScroll = () => {
    if (chatContainerRef?.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  };

  useEffect(() => {
    const container = chatContainerRef?.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      setShowScrollButton(!isAtBottom(container));
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [selectedChatHistory]);

  useEffect(() => {
    if (chatContainerRef?.current) {
      setShowScrollButton(!isAtBottom(chatContainerRef.current));
    }
  }, [messages]);
  return (
    <>
      {selectedChatHistory?.id !== -1 && (
        <>
          <div
            className={`ai-chat-main position-relative ${
              isExpand ? "expand-chat" : "collapse-chat"
            }`}>
            {isShowExpandBtn && (
              <div className="expand-collapse-inner">
                <Button
                  className="expand-collapse-btn"
                  type="text"
                  onClick={handleExpandOrCollapse}>
                  {isExpand ? "Expand" : "Collapse"}
                </Button>
              </div>
            )}
            <div className="ai-chat-inner">
              <div className="position-relative">
                <div className="chat-conversation" ref={chatContainerRef}>
                  {messages?.length > 0 && (
                    <ul>
                      {messages.map((message: IMessages, index) => {
                        return (
                          <React.Fragment key={index}>
                            {message.type === userType.AI ? (
                              <li>
                                <div className="conversation-list">
                                  <div className="ai-text-wrap">
                                    {message?.optimised_Question && (
                                      <div className="ai-searching ai-text">
                                        Searching for : <b>{message?.optimised_Question}</b>
                                      </div>
                                    )}
                                    {message?.isShowGeneratingLabel && (
                                      <div className="ai-generating  ai-text">
                                        Generating answers for you...
                                      </div>
                                    )}
                                  </div>
                                  <div className="ctext-wrap">
                                    <div className="chat-avatar">
                                      <img src={IMAGES.brandThumbnail} alt="Brand Thumbnail" />
                                    </div>
                                    {/* use selected class for select answer */}
                                    <div className="ctext-wrap-content">
                                      {/* <span className="relevent-assets">
                              Showing relevant assets to this query
                            </span> */}
                                      <Skeleton
                                        style={{ width: "200px" }}
                                        title={false}
                                        loading={
                                          !message?.content && message.isStopGenerating !== "TRUE"
                                        }
                                        active>
                                        <p>
                                          {message?.error ? (
                                            <ExclamationCircleOutlined className="error-icon" />
                                          ) : null}
                                          {message.isStopGenerating === "TRUE" &&
                                          message.content === "Generating..." ? (
                                            <i style={{ color: "#9e9e9e" }}>
                                              User has stopped generating.
                                            </i>
                                          ) : message.isStopGenerating === "TRUE" &&
                                            message.content !== "Generating..." ? (
                                            <>
                                              <Markdown className="mark-down">
                                                {message.content + "..."}
                                              </Markdown>
                                              <i style={{ color: "#9e9e9e" }}>
                                                User has stopped generating.
                                              </i>
                                            </>
                                          ) : (
                                            <Markdown className="mark-down">
                                              {message.content}
                                            </Markdown>
                                          )}
                                          {/* <span>{"{, . ,}"}</span> */}
                                        </p>
                                      </Skeleton>
                                      {message?.error ? (
                                        <div className="retryBtn">
                                          <Button
                                            className="m-10"
                                            // type="primary"
                                            // danger
                                            onClick={handleRetryBtn}>
                                            Retry
                                          </Button>
                                        </div>
                                      ) : null}
                                      {!message?.error && (
                                        <div className="acrion-btn-3 position-absolute">
                                          <Button
                                            className={message?.thumbsUp ? "btn active" : "btn"}
                                            type="text"
                                            onClick={() => {
                                              handleMessageActions(message, ChatActions.Like);
                                            }}>
                                            <span className="icon-span">
                                              <img src={IMAGES.goodIcon} alt="Brand Thumbnail" />
                                            </span>
                                            Good
                                          </Button>
                                          <Button
                                            className={message?.thumbsDown ? "btn active" : "btn"}
                                            type="text"
                                            onClick={() => {
                                              handleMessageActions(message, ChatActions.Dislike);
                                              setSelectedMessage(message);
                                            }}>
                                            <span className="icon-span">
                                              <img src={IMAGES.badIcon} alt="Brand Thumbnail" />
                                            </span>
                                            Bad
                                          </Button>
                                          <Button
                                            className="btn"
                                            type="text"
                                            onClick={() => {
                                              handleCopyClick(message.content);
                                            }}>
                                            <span className="icon-span">
                                              <img src={IMAGES.copyIcon} alt="Brand Thumbnail" />
                                            </span>
                                            Copy
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ) : (
                              <li className="right">
                                <div className="conversation-list">
                                  <div className="ctext-wrap">
                                    <div className="chat-avatar">
                                      <img src={IMAGES.userProfile} alt="User Thumbnail" />
                                    </div>
                                    <div className="ctext-wrap-content">
                                      <p>{message?.content}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  )}
                  {!!showScrollButton && (
                    <div className="chat-scroll-down position-absolute" onClick={scrollToBottom}>
                      <ArrowDownOutlined />
                    </div>
                  )}
                </div>
              </div>
              {/* Chat Input Section */}
              <Space.Compact className="chat-input-section">
                <Input
                  disabled={isDisableChat()}
                  placeholder="Ask further questions to find more relevant content"
                  value={userQuestion ? "" : question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                  onPressEnter={() => {
                    if (question.trim() || !isDisableChat()) {
                      handleAskFurther();
                    }
                  }}
                />
                {isStreamingStart && (
                  <div className="tooltip stop-button" onClick={handleStopGenerate}>
                    <img src={IMAGES.stopIcon} alt="stop icon" />
                    <span className="tooltiptext">Stop generating</span>
                  </div>
                )}
                <Button
                  type="primary"
                  disabled={!question.trim() || isDisableChat()}
                  onClick={handleAskFurther}>
                  <img src={IMAGES.askFurtherIcon} alt="Ask further" /> Ask further
                </Button>
              </Space.Compact>
            </div>
            {isComment && (
              <UserComments
                visible={isComment}
                message={selectedMessage}
                handleCancel={() => {
                  setIsComment(false);
                  setSelectedMessage(undefined);
                }}
              />
            )}
          </div>
          {selectedChatHistory?.id !== -1 &&
            !!messages?.length &&
            !!messages[messages.length - 1]?.citations?.length && <RelevanAssets />}
        </>
      )}
      {selectedChatHistory?.id !== -1 &&
        !!messages?.length &&
        !!messages[messages.length - 1]?.citations?.length && <OtherAssets />}
    </>
  );
};

export default Chat;
