import { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Input, Skeleton } from "antd";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/businessProposal/businessProposal.interface";
import {
  clearDataOnTopicStart,
  fieldsInitValue,
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { createPPTGeneartion, updatePPTGeneartion } from "../../../services/generation";
import { AssetUploadStatus, IMAGES } from "../../../shared";

interface ITopic {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const Topic = ({ localFields, setLocalFields }: ITopic) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, pptGeneration, isPPTPullingLoading, isPPTDataLoading } =
    useSelector((state: RootState) => state.busProposalSlice);

  const isLoadingOrDisable =
    isDisable?.topic || isLoading?.topic || isPPTPullingLoading || isLoading?.companyDetails;

  const clearLocalDataOnTopicStart = () => {
    setLocalFields({ ...fieldsInitValue, topic: localFields?.topic || "" });
  };

  const handleResetDisable = () => {
    dispatch(setUpdateIsDisable({ field: generationFields.topic, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.agenda, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.companyDetails, value: false }));
  };

  const handleUpdateReduxState = (trimmedTopic: string) => {
    dispatch(setUpdateFields({ field: generationFields.topic, value: trimmedTopic }));
  };

  const handleChangeTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalFields((field) => ({ ...field, topic: e.target.value }));
  };

  const handleTopicGetStarted = async () => {
    const trimmedTopic = localFields?.topic?.trim() || "";

    if (trimmedTopic) {
      let res: any = null;
      if (
        pptGeneration?.business_proposal_id &&
        ![String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED!].includes(
          pptGeneration?.status
        )
      ) {
        res = await updatePPTGeneartion(pptGeneration?.business_proposal_id, localFields?.topic);
      } else {
        res = await createPPTGeneartion(localFields?.topic);
      }

      if (res) {
        handleResetDisable();
        handleUpdateReduxState(trimmedTopic);
        clearLocalDataOnTopicStart();
        dispatch(clearDataOnTopicStart());
      }
    } else {
      toast.error("Topic input cannot be empty");
    }
  };

  return (
    <>
      <Card className="card-item card-enter-topic">
        <div className="card-header">
          <span className="card-number">1</span>
          <h2>Enter topic</h2>
        </div>

        <div className="card-content">
          {isPPTDataLoading ? (
            <Skeleton.Input className="skeleton-input" active={true} />
          ) : (
            <div className="generation-input position-relative">
              <Input
                type="text"
                placeholder="Enter topic"
                value={localFields?.topic ?? ""}
                onChange={handleChangeTopic}
                onKeyDownCapture={(event) => {
                  if (event.key === "Enter") {
                    handleTopicGetStarted();
                  }
                }}
                disabled={isLoadingOrDisable}
              />
              <div className="action-mode acn-flex acn-flex-middle  position-absolute">
                {isLoading?.topic && (
                  <div className="processing-wrap">
                    <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                    Processing
                  </div>
                )}
                <Button
                  type="primary"
                  className="outline-btn"
                  onClick={handleTopicGetStarted}
                  disabled={isLoadingOrDisable}>
                  Get started
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default Topic;
