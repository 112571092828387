import { TableColumnsType } from "antd";
import {
  IFunctions,
  IFunctionsTableData,
  IProcess,
  IProcessTableData,
  IRelevantAsset,
  ISubProcess,
  ISubProcessTableData,
  TableDataType,
} from "../store/generation/kpis/kpiGeneration.interface";
import { AssetUploadStatus, IMAGES, ISelect, isEmpty, PATHS } from "../shared";
import { getThumbnailsByPageForKpi } from "../services/generation";
import { ITaxonomyTree } from "../store/taxonomy/taxonomy.interface";
import { IAgenda } from "../store/generation/businessProposal/businessProposal.interface";

const generationAreaType = [
  {
    href: PATHS.generateKPIs,
    icon: IMAGES.generateKpis,
    title: "Generate KPIs",
  },
  {
    href: "",
    icon: IMAGES.designProcessFlows,
    title: "Design Process Flows",
  },
  {
    href: "",
    icon: IMAGES.generateDigitalUseCases,
    title: "Generate Digital Use Cases",
  },
  {
    href: "",
    icon: IMAGES.generateGenAIUseCases,
    title: "Generate Gen AI Use Cases",
  },
  {
    href: "",
    icon: IMAGES.generateForMultipleAreas,
    title: "Generate for Multiple Areas",
  },
  {
    // href: "",
    href: PATHS.generateBusinessProposal,
    icon: IMAGES.generateDigitalUseCases,
    title: "Generate Business Proposal",
  },
];

const tagsColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "key",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Tags",
    dataIndex: "name",
  },
];

const functionsColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "index",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Functions",
    dataIndex: "name",
  },
];

const exportDocColumns: TableColumnsType<TableDataType> = [
  {
    title: "Kindly select the format of the document you wish to generate the file in",
    dataIndex: "name",
  },
];

const exportDocData: TableDataType[] = [
  {
    key: 1,
    name: "CSV",
  },
];

const agendaColumn: TableColumnsType<TableDataType> = [
  {
    title: "No.",
    dataIndex: "index",
    rowScope: "row",
    width: "30px",
  },
  {
    title: "Agenda",
    dataIndex: "name",
  },
];

const getFormattedFunction = (list: IFunctions[]) => {
  let formattedList: IFunctionsTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: item.id, name: item.function, index: index + 1 });
    });
  }
  return formattedList;
};

const getFormattedProcess = (list: IProcess[]) => {
  let formattedList: IProcessTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: index + 1, name: item.process, index: index + 1 });
    });
  }
  return formattedList;
};

const getFormattedSubProcess = (list: ISubProcess[]) => {
  let formattedList: ISubProcessTableData[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({ ...item, key: index + 1, name: item.sub_process, index: index + 1 });
    });
  }
  return formattedList;
};

const getIsProcessing = (status: string) =>
  [String(AssetUploadStatus.SENT_TO_LLM), AssetUploadStatus.PROCESSING].includes(status);

const getIsDisable = (status: string) =>
  [String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED].includes(status);

const getIsEnable = (status: string) =>
  [String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED].includes(status);

const getIsFetchingDone = (status: string) => getIsDisable(status);

const getSelectedFunction = (functions: any[]) => {
  const selectedFunction =
    functions && functions?.length ? functions?.filter((fun) => fun.is_selected) : [];
  return getFormattedFunction(selectedFunction);
};

const getSelectedProcess = (process: any[]) => {
  const selectedProcess =
    process && process?.length ? process?.filter((item) => item.is_selected) : [];
  return getFormattedProcess(selectedProcess);
};

const getSelectedSubProcess = (subProcess: any[]) => {
  const selectedSubProcess =
    subProcess && subProcess?.length ? subProcess?.filter((item) => item.is_selected) : [];
  return getFormattedSubProcess(selectedSubProcess);
};

const getSelectedFunKeys = (list: any[]) => {
  const keys: number[] = [];
  if (list && list?.length) {
    list.forEach((item) => {
      if (item.is_selected) {
        keys.push(item.id);
      }
    });
  }

  return keys;
};

const getListKeys = (list: any[]) => {
  let keys: number[] = [];
  if (list && list.length) {
    keys = list.map((item) => item?.id);
  }

  return keys;
};

export const handleSetThumbnailUrl = (relevantAssets: IRelevantAsset[]) => {
  if (!isEmpty(relevantAssets)) {
    const request = relevantAssets?.map((item: IRelevantAsset) => {
      return { file_name: item.file_name, page_no: item?.page };
    });
    getThumbnailsByPageForKpi(request);
  }
};

export const groupItemsByFileName = (items: IRelevantAsset[]): IRelevantAsset[] => {
  const grouped: any = {};

  items.forEach((item) => {
    const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
    if (!(key in grouped)) {
      grouped[key] = {
        asset_id: item.asset_id,
        title: item.title,
        file_name: item.file_name,
        original_file_name: item.original_file_name,
        page: item.page,
        file_type: item?.file_type,
        is_editable: item?.is_editable,
        pages: [],
      };
    }
    if (!grouped[key].pages.includes(item.page)) {
      grouped[key].pages.push(item.page);
    }
  });

  return Object.values(grouped);
};

export const getFirstLevelOptions = (data: ITaxonomyTree[]) => {
  let options: ISelect[] = [];

  if (!isEmpty(data)) {
    data.forEach((item) => {
      options.push({ label: item.taxonomyNodeName, value: item.taxonomyNodeId });
    });
  }
  return options;
};

const AGENDA_OPT = [
  {
    agendaId: 1,
    agendaName: "executive_summary",
    is_selected: true,
  },
  {
    agendaId: 2,
    agendaName: "company_overview",
    is_selected: false,
  },
  {
    agendaId: 3,
    agendaName: "understanding_of_scope",
    is_selected: false,
  },
  {
    agendaId: 4,
    agendaName: "approach",
    is_selected: false,
  },
  {
    agendaId: 5,
    agendaName: "about_accenture",
    is_selected: false,
  },
];

const getFormattedAgenda = (list: IAgenda[]) => {
  let formattedList: any[] = [];
  if (list && list.length) {
    list.forEach((item, index) => {
      formattedList.push({
        ...item,
        key: item?.agendaId,
        name: AGENDA_TITLE[item?.agendaName],
        index: index + 1,
      });
    });
  }
  return formattedList;
};

const getDefaultSelectedAgendaId = () => {
  const agendaObj = AGENDA_OPT.find((opt) => opt?.agendaName === "executive_summary");
  return agendaObj?.agendaId || -1;
};

const AGENDA_TITLE: any = {
  executive_summary: "Executive summary",
  company_overview: "Company Overview",
  understanding_of_scope: "Our Understanding of the Scope",
  approach: "Approach & Methodology",
  about_accenture: "About Accenture",
};

export {
  generationAreaType,
  tagsColumn,
  functionsColumn,
  exportDocColumns,
  exportDocData,
  agendaColumn,
  getFormattedFunction,
  getFormattedProcess,
  getFormattedSubProcess,
  getIsProcessing,
  getIsDisable,
  getIsEnable,
  getIsFetchingDone,
  getSelectedFunction,
  getSelectedProcess,
  getSelectedSubProcess,
  getSelectedFunKeys,
  getFormattedAgenda,
  getListKeys,
  AGENDA_OPT,
  getDefaultSelectedAgendaId,
  AGENDA_TITLE,
};
