import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table } from "antd";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/businessProposal/businessProposal.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { IMAGES, isEmpty } from "../../../shared";
import { agendaColumn, getFormattedAgenda } from "../../../helper/GenerationHelper";
import { savePPTAgenda } from "../../../services/generation";
import { SkeletonTable } from "../kpiSection";

interface IAgenda {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const Agenda = ({ localFields, setLocalFields }: IAgenda) => {
  const dispatch = useDispatch();
  const { isLoading, isDisable, pptGeneration, agendaOpt, isPPTPullingLoading, isPPTDataLoading } =
    useSelector((state: RootState) => state.busProposalSlice);
  const loading =
    isDisable?.agenda ||
    isLoading?.agenda ||
    isPPTPullingLoading ||
    !isEmpty(pptGeneration?.agendas);

  const handleUpdateReduxState = () => {
    dispatch(setUpdateFields({ field: generationFields.agenda, value: localFields?.agenda }));
  };

  const handleSelectAgenda = (keys: any[]) => {
    setLocalFields((field) => ({ ...field, agenda: keys }));
  };

  const rowSelection = {
    columnTitle: () => <div style={{ visibility: "hidden" }}></div>,
    selectedRowKeys: localFields?.agenda || [],
    onChange: handleSelectAgenda,
    getCheckboxProps: (record: any) => ({
      disabled: loading || record?.agendaName === "executive_summary",
    }),
  };

  const getFormattedAgendaInput = () => {
    const selectedAgenda = localFields?.agenda || [];
    let formattedAgendaInput: any[] = [];

    if (!isEmpty(agendaOpt)) {
      agendaOpt.forEach((opt) => {
        if (selectedAgenda.includes(opt?.agendaId || -1)) {
          formattedAgendaInput.push({
            agendaName: opt?.agendaName,
            is_selected: true,
          });
        } else {
          formattedAgendaInput.push({
            agendaName: opt?.agendaName,
            is_selected: false,
          });
        }
      });
    }
    return formattedAgendaInput;
  };

  const handleNext = async () => {
    if (!isEmpty(localFields?.agenda)) {
      const formattedAgendaInput = getFormattedAgendaInput();
      const res = await savePPTAgenda(pptGeneration?.business_proposal_id, formattedAgendaInput);

      if (res) {
        handleUpdateReduxState();
        dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.agenda, value: true }));
      }
    } else {
      toast.error("Please select agenda");
    }
  };

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">3</span>
          <h2>Selected Agenda</h2>
        </div>

        <div className="card-content">
          {isPPTDataLoading ? (
            <SkeletonTable />
          ) : (
            <Table
              rowSelection={rowSelection}
              columns={agendaColumn}
              dataSource={getFormattedAgenda(agendaOpt)}
              pagination={false}
            />
          )}
        </div>

        {!isPPTDataLoading && (
          <div className="card-footer acn-flex acn-flex-end">
            <div className="card-footer-right acn-flex acn-flex-middle">
              {isLoading?.agenda && (
                <div className="processing-wrap">
                  <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                  Processing
                </div>
              )}
              <Button
                type="primary"
                className="outline-btn"
                disabled={loading}
                onClick={handleNext}>
                Next
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default Agenda;
