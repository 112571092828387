import { toast } from "react-toastify";
import { get, post, put } from "./apiClients";
import { store } from "../store";
import {
  generationFields,
  kpiGenerationsInitValue,
  setIsGetRelevantAssetLoading,
  setIsSaveRelevantAssetLoading,
  setKpiGenerations,
  setUpdateIsLoading,
  setIsThumbnailLoading,
  setThumbnailInAsset,
} from "../store/generation/kpis/kpiGenerationSlice";
import {
  clearFields,
  generationFields as generationFieldsBusProp,
  pptGenerationInitValue,
  setIsDocDownloading,
  setPPTGeneration,
  setUpdateIsLoading as setUpdateIsLoadingBusProp,
} from "../store/generation/businessProposal/businessProposalSlice";
import { AssetUploadStatus, isEmpty } from "../shared";
import {
  IGetThumbnailsByPage,
  IRelevantAssetsRequest,
} from "../store/generation/kpis/kpiGeneration.interface";
import { IAgenda } from "../store/generation/businessProposal/businessProposal.interface";

export const fetchKpiFunctionsByTopic = async (prompt: string) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: true }));
    const { data } = await post(`/core/kpi`, {
      prompt,
    });

    if (data) {
      store.dispatch(setKpiGenerations(data));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to create KPI");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: false }));
  }
};

export const updateKpiFunctionsByTopic = async (kpiId: number, prompt: string) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: true }));
    const { data } = await put(`/core/kpi/${kpiId}`, {
      prompt,
    });

    if (data) {
      store.dispatch(setKpiGenerations(data));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to save topic");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.topic, value: false }));
  }
};

export const fetchKpiProcessByFunction = async (kpiId: number, functionsIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.functions, value: true }));
    const res = await get(`/core/kpi/generate-processes`, {
      params: { kpi_id: kpiId, function_ids: String(functionsIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate process");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.functions, value: false }));
  }
};

export const fetchKpiSubProcessByProcess = async (kpiId: number, processIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.process, value: true }));
    const res = await get(`/core/kpi/generate-sub-processes`, {
      params: { kpi_id: kpiId, process_ids: String(processIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate sub-process");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.process, value: false }));
  }
};

export const fetchKpiDocBySubProcess = async (kpiId: number, subProcessIds: React.Key[]) => {
  try {
    store.dispatch(setUpdateIsLoading({ field: generationFields.exportDoc, value: true }));
    const res = await get(`/core/kpi/generate-document`, {
      params: { kpi_id: kpiId, sub_process_ids: String(subProcessIds) },
    });

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to export document");
  } finally {
    store.dispatch(setUpdateIsLoading({ field: generationFields.exportDoc, value: false }));
  }
};

export const fetchPendingKpis = async (kpiId: number | null) => {
  try {
    let res;
    if (kpiId) {
      res = await get(`/core/kpi/${kpiId}`);
    } else {
      res = await get(`/core/kpi/latest`);
    }

    if (res?.process_status === AssetUploadStatus.FAILED) {
      toast.error("Process generation failed. Please restart the generation.");
    } else if (res?.sub_process_status === AssetUploadStatus.FAILED) {
      toast.error("Sub-process generation failed. Please restart the generation.");
    } else if (res?.document_status === AssetUploadStatus.FAILED) {
      toast.error("Document generation failed. Please restart the generation.");
    } else if (res?.function_status === AssetUploadStatus.FAILED) {
      toast.error("Function generation failed. Please restart the generation.");
    }

    if (res) {
      store.dispatch(setKpiGenerations(res));
    } else {
      store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
    }
    return res;
  } catch (error: any) {
    if (error?.errorMessage) {
      toast.error(error?.errorMessage);
    }
    store.dispatch(setKpiGenerations(kpiGenerationsInitValue));
  }
};

export const fetchRelevantAsset = async (prompt: string) => {
  try {
    store.dispatch(setIsGetRelevantAssetLoading(true));
    const res = await get(`/core/kpi/relevant-asset`, { params: { prompt } });
    // store.dispatch(setRelevantAssets(res || []));
    return res;
  } catch (error: any) {
    // store.dispatch(setRelevantAssets([]));
    toast.error(error?.errorMessage ?? "Failed to get relevant asset");
  } finally {
    store.dispatch(setIsGetRelevantAssetLoading(false));
  }
};

export const getThumbnailsByPageForKpi = async (request: IGetThumbnailsByPage[]) => {
  try {
    store.dispatch(setIsThumbnailLoading(true));
    const res = await post("core/asset/getThumbnailByPage", request);
    store.dispatch(setThumbnailInAsset(res?.data || []));
    return res;
  } catch (error) {
    console.log("Error while fetching Thumbnail", error);
  } finally {
    store.dispatch(setIsThumbnailLoading(false));
  }
};

export const postRelevantAsset = async (
  kpiId: number,
  relevantAssets: IRelevantAssetsRequest[]
) => {
  try {
    store.dispatch(setIsSaveRelevantAssetLoading(true));
    const res = await put(`/core/kpi/${kpiId}/relevant-assets`, {
      kpi_id: kpiId,
      relevant_assets: relevantAssets,
    });
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to save relevant asset");
    return error;
  } finally {
    store.dispatch(setIsSaveRelevantAssetLoading(false));
  }
};

// Proposal Generation
export const createPPTGeneartion = async (prompt: string) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: true })
    );
    const { data } = await post(`/core/businessProposal/`, { prompt: prompt });

    if (data) {
      store.dispatch(setPPTGeneration(data));
    } else {
      store.dispatch(setPPTGeneration(pptGenerationInitValue));
    }
    toast.success("Topic has been successfully saved");
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to save topic");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: false })
    );
  }
};

export const updatePPTGeneartion = async (pptGenerationId: number, prompt: string) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: true })
    );
    const { data } = await put(`/core/businessProposal/${pptGenerationId}`, {
      prompt,
    });

    if (data) {
      store.dispatch(setPPTGeneration(data));
    } else {
      store.dispatch(setPPTGeneration(pptGenerationInitValue));
    }
    toast.success("Topic has been successfully updated");
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to update topic");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.topic, value: false })
    );
  }
};

export const savePPTIndustry = async (industryId: number, pptGenerationId: number) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.industryFun, value: true })
    );
    const { data } = await post(`/core/businessProposal/industry`, {
      taxonomy_node_id: industryId,
      business_proposal_id: pptGenerationId,
    });

    store.dispatch(setPPTGeneration(data));
    toast.success("Industry has been successfully saved");
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to save industry");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.industryFun, value: false })
    );
  }
};

export const savePPTAgenda = async (pptGenerationId: number, agendas: IAgenda[]) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.agenda, value: true })
    );
    const { data } = await post(`/core/businessProposal/agenda`, {
      business_proposal_id: pptGenerationId,
      agendas: agendas, // TODO: Need to change
    });

    store.dispatch(setPPTGeneration(data));
    toast.success("Agenda has been successfully saved");
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to save agenda");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.agenda, value: false })
    );
  }
};

export const saveCompanyDetails = async (
  pptGenerationId: number,
  companyName: string,
  formData: FormData
) => {
  try {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.companyDetails, value: true })
    );
    const { data } = await post(`/core/businessProposal/addCompanyDetails`, formData, {
      params: {
        id: pptGenerationId,
        companyName: !isEmpty(companyName) ? companyName : "",
      },
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log("percentCompleted  ===", percentCompleted);
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (data) {
      store.dispatch(setPPTGeneration(data));
    }
    toast.success("Business Proposal generation has been successfully started");
    return data;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to generate Business Proposal");
  } finally {
    store.dispatch(
      setUpdateIsLoadingBusProp({ field: generationFieldsBusProp.companyDetails, value: false })
    );
  }
};

export const fetchPPTGeneration = async (pptGenerationId: number | null) => {
  try {
    let res;
    if (pptGenerationId) {
      res = await get(`/core/businessProposal/${pptGenerationId}`);
    } else {
      res = await get(`/core/businessProposal/latest`);
    }

    if (res) {
      store.dispatch(setPPTGeneration(res));
    } else {
      store.dispatch(setPPTGeneration(pptGenerationInitValue));
    }

    if (res?.status === AssetUploadStatus.FAILED) {
      toast.error("Business Proposal generation failed. Please restart the generation.");
    }
    return res;
  } catch (error: any) {
    store.dispatch(setPPTGeneration(pptGenerationInitValue));
    store.dispatch(clearFields());
    toast.error(error?.errorMessage ?? "Failed to get Business Proposal");
  }
};

export const exportPPTDoc = async (pptGenerationId: number | null) => {
  try {
    store.dispatch(setIsDocDownloading(true));

    const res = await get(`/core/businessProposal/${pptGenerationId}/export`, {
      responseType: "blob",
    });

    const fileName = `Proposal_${pptGenerationId}.pptx`;
    const blobUrl = URL.createObjectURL(res);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(link);

    toast.success("Document successfully exported");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage ?? "Failed to export document");
  } finally {
    store.dispatch(setIsDocDownloading(false));
  }
};
