import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IContent, IContentSliceProps, ITagsByAssestId, IContentFilter } from "./content.interface";
import { AssetUploadStatus, PublishedOption } from "../../shared";
import { autoRefreshedAssetById } from "../../services/contentManagement";
import { PURGE } from "redux-persist";

export const tagsByAssestIdInitValue: ITagsByAssestId = {
  assetId: null,
  linkedTaxonomyNodes: {
    Industry: [],
    Function: [],
    "Asset Type": [],
    "Reference Keywords": [],
  },
  taxonomyTree: [],
};

export const contentByIdInitialValue: IContent = {
  asset_id: -1,
  file_name: "",
  original_file_name: "",
  pre_signed_url: "",
  bucket_name: "",
  assetStatus: "",
  error_detail: null,
  user: {
    status: "",
    id: -1,
    username: "",
    name: "",
    email: "",
    userPermissions: [],
  },
  viewedOn: "",
  title: "",
  assetType: "",
  summary: "",
  tags: [],
  published: "",
  contributors: [],
  isShowFullSummary: false,
  isShowFullTags: false,
  isPublished: false,
  uploadedOn: "",
  function: "",
  industry: "",
  assetTypeSelected: "",
  modifiedOn: "",
  publishedOn: "",
  linkedTags: tagsByAssestIdInitValue,
  thumbnail_pre_signed_url: "",
  thumbnail_object_path: "",
  asset_url: null,
  file_type: "",
  inputFileName: "",
  visionParsed: false,
  summaryStatus: "",
  ai_generated_summary: null,
};

export const contentFilterField = {
  currentPage: "currentPage",
  publishedFilter: "publishedFilter",
  searchText: "searchText",
};

export const contentFilterInitValue: IContentFilter = {
  currentPage: 1,
  publishedFilter: PublishedOption[0].value,
  searchText: "",
};

const initialState: IContentSliceProps = {
  contents: [],
  isContentTableLoading: false,
  isContentByIdLoading: false,
  contentById: contentByIdInitialValue,
  isEditContentLoading: false,
  selectedAllTagByAssestId: tagsByAssestIdInitValue,
  isTagsByAssestIdLoading: false,
  isUpdateTagsByAssestIdLoading: false,
  totalContentsCount: 0,
  contentDeleteLoading: false,
  contentFilter: contentFilterInitValue,
  exportCSV: "",
  isExportCSVLoading: false,
  fileUploadProgress: 0,
  urlUploadProgress: 0,
  downloadLogError: null,
  downloadLimit: 0,
  isFetchDownloadLimit: false,
  isSaveDownloadLimit: false,
  isUserListLoading: false,
  userPageCount: 1,
  isRunVisionLoading: false,
  isProcessStopLoading: false,
  isSummaryGenerationLoading: false,
  isFileProcessing: false,
  selectedAssetId: -1,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAllContentDetails: (state, action: PayloadAction<IContent[]>) => {
      state.contents = action.payload;
    },

    updateUploadedContent: (state, action: PayloadAction<IContent>) => {
      state.contents.push(action.payload);
    },

    setIsContentTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isContentTableLoading = action.payload;
    },

    setContentById: (state, action: PayloadAction<IContent>) => {
      state.contentById =
        state.selectedAssetId === action.payload?.asset_id ? action.payload : state.contentById;
    },

    setIsContentByIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isContentByIdLoading = action.payload;
    },

    setIsEditContentLoading: (state, action: PayloadAction<boolean>) => {
      state.isEditContentLoading = action.payload;
    },

    removeContributor: (state, action: PayloadAction<number>) => {
      const contributors = state.contentById?.contributors.filter((v) => v.id !== action.payload);
      state.contentById = {
        ...state.contentById,
        contributors,
      };
    },

    setShowMoreSummary: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isShowFullSummary = true;
      state.contents = values;
    },

    setShowMoreTags: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isShowFullTags = true;
      state.contents = values;
    },

    setUpdatePublishDraft: (state, action: PayloadAction<number>) => {
      let values = [...state.contents];
      values[action.payload].isPublished = ["TRUE", true].includes(
        values[action.payload].isPublished
      )
        ? false
        : true;
      state.contents = values;
    },

    setSelectedAllTagsByAssestId: (state, action: PayloadAction<ITagsByAssestId>) => {
      state.selectedAllTagByAssestId = action.payload;
    },

    setIsAllTagsByAssestIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isTagsByAssestIdLoading = action.payload;
    },

    setIsUpdateTagsByAssestIdLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdateTagsByAssestIdLoading = action.payload;
    },

    autoRefreshContent: (_, action: PayloadAction<{ assetId: number }>) => {
      let res: IContent;
      if (action.payload.assetId !== -1) {
        let interval = setInterval(async () => {
          res = await autoRefreshedAssetById(action.payload.assetId);

          if (res?.error) {
            clearInterval(interval);
          } else {
            !!res &&
              res.assetStatus === AssetUploadStatus.LOADED_INTO_LLM &&
              clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.COMPLETED && clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.FAILED && clearInterval(interval);
            !!res && res.assetStatus === AssetUploadStatus.STOPPED && clearInterval(interval);
          }
        }, 5000);
      }
    },

    refreshedAssetByAssetId: (state, action: PayloadAction<{ data: IContent; id: number }>) => {
      state.contents = state.contents.map((v) =>
        v.asset_id === action.payload.id ? action.payload.data : v
      );
      if (action.payload.data?.asset_id === state.selectedAssetId) {
        state.contentById = action.payload.data;
      }
    },


    setTotalContentCount: (state, action: PayloadAction<number>) => {
      state.totalContentsCount = action.payload;
    },

    setContentDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.contentDeleteLoading = action.payload;
    },

    setUpdateContentFilter: (
      state,
      action: PayloadAction<{ field: string; value: string | number }>
    ) => {
      state.contentFilter = {
        ...state.contentFilter,
        [action.payload.field]: action.payload.value,
      };
    },

    clearContentFilter: (state) => {
      state.contentFilter = contentFilterInitValue;
    },

    setContentExportCSV: (state, action: PayloadAction<string>) => {
      state.exportCSV = action.payload;
    },

    setIsContentExportCSVLoading: (state, action: PayloadAction<boolean>) => {
      state.isExportCSVLoading = action.payload;
    },

    setFileUploadProgress: (state, action: PayloadAction<number>) => {
      state.fileUploadProgress = action.payload;
    },

    setURLUploadProgress: (state, action: PayloadAction<number>) => {
      state.urlUploadProgress = action.payload;
    },

    setDownloadLogError: (state, action: PayloadAction<string | null>) => {
      state.downloadLogError = action.payload;
    },
    setDownloadLimit: (state, action: PayloadAction<number>) => {
      state.downloadLimit = action.payload;
    },
    setIsFetchDownloadLimit: (state, action: PayloadAction<boolean>) => {
      state.isFetchDownloadLimit = action.payload;
    },
    setIsSaveDownloadLimit: (state, action: PayloadAction<boolean>) => {
      state.isSaveDownloadLimit = action.payload;
    },
    setIsUserListLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserListLoading = action.payload;
    },
    setUserListPageCount: (state, action: PayloadAction<number>) => {
      state.userPageCount = action.payload;
    },
    setIsRunVisionLoading: (state, action: PayloadAction<boolean>) => {
      state.isRunVisionLoading = action.payload;
    },
    updateAssetByAssetId: (state, action: PayloadAction<{ data: IContent; id: number }>) => {
      state.contents = state.contents.map((v) =>
        v.asset_id === action.payload.id ? action.payload.data : v
      );
    },
    setIsProcessStopLoading: (state, action: PayloadAction<boolean>) => {
      state.isProcessStopLoading = action.payload;
    },
    setIsSummaryGenerationLoading: (state, action: PayloadAction<boolean>) => {
      state.isSummaryGenerationLoading = action.payload;
    },

    updatePageOnContentDelete: (state, action: PayloadAction<number>) => {
      state.contentFilter.currentPage = action.payload;
    },
    setIsFileProcessing: (state, action: PayloadAction<boolean>) => {
      state.isFileProcessing = action.payload;
    },
    setSelectedAssetId: (state, action: PayloadAction<number>) => {
      state.selectedAssetId = action.payload;
    },
  },
});

export const {
  setContentById,
  setShowMoreTags,
  removeContributor,
  setShowMoreSummary,
  autoRefreshContent,
  setAllContentDetails,
  setUpdatePublishDraft,
  updateUploadedContent,
  setIsEditContentLoading,
  setIsContentByIdLoading,
  refreshedAssetByAssetId,
  setIsContentTableLoading,
  setSelectedAllTagsByAssestId,
  setIsAllTagsByAssestIdLoading,
  setIsUpdateTagsByAssestIdLoading,
  setTotalContentCount,
  setContentDeleteLoading,
  setUpdateContentFilter,
  clearContentFilter,
  setContentExportCSV,
  setIsContentExportCSVLoading,
  setFileUploadProgress,
  setURLUploadProgress,
  setDownloadLogError,
  setDownloadLimit,
  setIsFetchDownloadLimit,
  setIsSaveDownloadLimit,
  setIsUserListLoading,
  setUserListPageCount,
  setIsRunVisionLoading,
  updateAssetByAssetId,
  setIsProcessStopLoading,
  setIsSummaryGenerationLoading,
  updatePageOnContentDelete,
  setIsFileProcessing,
  setSelectedAssetId
} = userSlice.actions;

export default userSlice.reducer;
