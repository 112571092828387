import { Dispatch, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Input, Skeleton, Tooltip } from "antd";

import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  clearDataOnTopicStart,
  fieldsInitValue,
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { fetchKpiFunctionsByTopic, updateKpiFunctionsByTopic } from "../../../services/generation";
import { AssetUploadStatus, IMAGES } from "../../../shared";
import { getIsProcessing } from "../../../helper/GenerationHelper";

interface IKpiTopic {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
  setIsGotRelevantAsset: Dispatch<SetStateAction<boolean>>;
}

const KpiTopic = ({ localFields, setLocalFields, setIsGotRelevantAsset }: IKpiTopic) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, isAllLoading, kpiGenerations, isKpiDataLoading } = useSelector(
    (state: RootState) => state.kpiGeneration
  );
  const loading = isDisable?.topic || isLoading?.topic || isAllLoading;

  const clearLocalDataOnTopicStart = () => {
    setLocalFields({ ...fieldsInitValue, topic: localFields?.topic || "" });
  };

  const handleResetDisable = () => {
    dispatch(setUpdateIsDisable({ field: generationFields.topic, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.functions, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.process, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.subProcess, value: false }));
    dispatch(setUpdateIsDisable({ field: generationFields.exportDoc, value: false }));
  };

  const handleChangeTopic = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalFields((field) => ({ ...field, topic: e.target.value }));
  };

  const handleTopicGetStarted = async () => {
    const trimmedTopic = localFields?.topic?.trim() || "";
    if (trimmedTopic) {
      clearLocalDataOnTopicStart();
      dispatch(clearDataOnTopicStart());
      setIsGotRelevantAsset(false);

      let res: any = null;
      if (
        kpiGenerations?.kpi_id &&
        kpiGenerations?.document_status !== AssetUploadStatus.COMPLETED
      ) {
        res = await updateKpiFunctionsByTopic(kpiGenerations?.kpi_id, trimmedTopic);
      } else {
        res = await fetchKpiFunctionsByTopic(trimmedTopic);
      }

      if (res) {
        handleResetDisable();
        dispatch(setUpdateFields({ field: generationFields.topic, value: trimmedTopic }));
        dispatch(setUpdateIsShow({ field: generationFields.functions, value: true }));
      }
    } else {
      toast.error("Topic input cannot be empty");
    }
  };

  return (
    <>
      <Card className="card-item card-enter-topic">
        <div className="card-header">
          <span className="card-number">1</span>
          <h2>
            Enter topic
            <Tooltip
              title="Your prompt is set to semantically filter and identify the relevant functions, processes, and subprocesses."
              color="#fff"
              placement="topLeft"
              overlayClassName="tooltip-text tooltip-ui-new">
              <i className="ri-information-line cursor-pointer"></i>
            </Tooltip>
          </h2>
        </div>

        <div className="card-content">
          {isKpiDataLoading ? (
            <Skeleton.Input className="skeleton-input" active={true} />
          ) : (
            <div className="generation-input position-relative">
              <Input
                type="text"
                placeholder="Enter topic"
                value={localFields?.topic ?? ""}
                onChange={handleChangeTopic}
                onKeyDownCapture={(event) => {
                  if (event.key === "Enter") {
                    handleTopicGetStarted();
                  }
                }}
                disabled={loading}
              />
              <div className="action-mode acn-flex acn-flex-middle  position-absolute">
                {(isLoading?.topic || getIsProcessing(kpiGenerations?.function_status)) && (
                  <div className="processing-wrap">
                    <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                    Processing
                  </div>
                )}
                <Button
                  type="primary"
                  className="outline-btn"
                  disabled={loading}
                  onClick={handleTopicGetStarted}>
                  Get started
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};

export default KpiTopic;
