import "./TaxonomyOverview.scss";
import { Card, Col, Row, Typography, Input, Form, Button } from "antd";
import { IMAGES, TaxonomyType, TaxonomyView } from "../../../shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TaxonomyTree } from "../taxonomyTree";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { fetchTaxonomyById, updateTaxonomy } from "../../../services/taxonomy";
import { TaxonomyFlat } from "../taxonomyFlat";
import { toast } from "react-toastify";

const TaxonomyOverview = () => {
  const location = useLocation();

  const [colLevel, setColLevel] = useState<number>(-1);
  const [taxonomyView, setTaxonomyView] = useState(TaxonomyView.TreeHierarchy);
  const [isEditMode, setIsEditMode] = useState<boolean>(location?.state?.isEditMode);

  const { id } = useParams();

  const navigate = useNavigate();

  const taxonomyId: number = Number(id) > 0 ? Number(id) : -1;

  const [form] = Form.useForm();

  const { getTaxonomyTree } = useSelector((state: RootState) => state.taxonomy);

  useEffect(() => {
    if (getTaxonomyTree?.taxonomyType) {
      const taxonomyType =
        getTaxonomyTree?.taxonomyType === TaxonomyType.TREE
          ? TaxonomyView.TreeHierarchy
          : TaxonomyView.FlatKeywords;
      setTaxonomyView(taxonomyType);
      setColLevel(Number(getTaxonomyTree.levels));
    }

    let totalLength = 0;
    const parseTree = getTaxonomyTree?.taxonomyUI ? JSON.parse(getTaxonomyTree?.taxonomyUI) : [];

    getTaxonomyTree?.taxonomyType === TaxonomyType.TREE &&
      parseTree?.length &&
      parseTree.forEach((obj: any) => {
        obj.tree.forEach((treeObj: any) => {
          totalLength += treeObj?.level?.length;
        });
      });

    form.setFieldsValue(getTaxonomyTree);
  }, [getTaxonomyTree]);

  const renderTaxonomyView = () => {
    if (taxonomyView === TaxonomyView.TreeHierarchy) {
      return (
        <>
          <div className="no-terms-text">No. of terms: {getTaxonomyTree?.termsCount ?? 0}</div>
          <img src={IMAGES.organogramIconPng} alt="organogram" />
          <div className="tree-hierarchy-text">Tree hierarchy</div>
        </>
      );
    } else {
      return (
        <>
          <img src={IMAGES.flatKeywordIconPng} alt="flatKeyword" />
          <div className="tree-hierarchy-text">Flat keywords</div>
        </>
      );
    }
  };

  const onFinish = async (values: { title: string; purpose: string }) => {
    if (!values?.title) {
      toast.error("Please enter title");
      return;
    }

    const setBasicValues = {
      ...values,
      taxonomyType: getTaxonomyTree?.taxonomyType,
      levels: getTaxonomyTree?.levels ?? 0,
    };

    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      const prevTitle = getTaxonomyTree?.title || "";
      const prevPurpose = getTaxonomyTree?.purpose || "";

      if (prevTitle !== values?.title || prevPurpose !== values?.purpose) {
        await updateTaxonomy(taxonomyId, setBasicValues);
        await fetchTaxonomyById(taxonomyId);
      }
      setIsEditMode(false);
    }
  };

  const renderTaxonomyFooter = () => {
    if (taxonomyView === TaxonomyView.TreeHierarchy) {
      return (
        <Col className="tree-hierarchy" span={24}>
          <Card>
            <TaxonomyTree colLevel={colLevel} />
          </Card>
        </Col>
      );
    } else {
      return (
        <Col className="global-table-card table-ui flat-taxonomy-table" span={24}>
          <Card>
            {/* <TaxonomyFlat tableForm={form} setDataTable={setDataTable} /> */}
            <TaxonomyFlat colLevel={1} />
          </Card>
        </Col>
      );
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    form.setFieldsValue(getTaxonomyTree);
  };

  const renderLevel = (level?: number) => {
    if (level === undefined) return 0;
    return level < 10 ? `0${level}` : level;
  };

  return (
    <div className="page-content top-container user-management-page taxonomy-management-overview">
      <Row>
        <Col className="page-title-card" span={24}>
          <Card>
            <div className="page-title-box acn-flex acn-flex-middle acn-flex-between">
              <div className="page-title-left title-back-iconWrap">
                <img src={IMAGES.backIcon} alt="back" onClick={() => navigate(-1)} />
                <Typography className="card-heading">Overview</Typography>
              </div>
              <div className="organogram-icon-Wrap">{renderTaxonomyView()}</div>
            </div>
          </Card>
        </Col>

        <Col className="global-table-card table-ui page-title-card" span={24}>
          <Card>
            <Form form={form} onFinish={onFinish}>
              <div className="taxonomy-overview-content">
                <div className="taxonomy-overview-header-wrap">
                  <div className="taxonomy-overview-content-header">
                    <Form.Item name="title" rules={[{ required: false }]}>
                      <Input
                        className={`input-style input-title ${isEditMode && "edit-input-style"}`}
                        value={getTaxonomyTree?.title}
                        readOnly={isEditMode === false ? true : false}
                        style={{ width: "10", maxWidth: "10" }}
                      />
                    </Form.Item>
                  </div>
                  <div className="organogram-icon-Wrap">
                    <div className="level-select acn-flex acn-flex-middle levelTextButtonWrap">
                      <div className="no-terms-text">
                        {`${renderLevel(getTaxonomyTree?.levels)} level`}
                      </div>

                      {isEditMode != true ? (
                        <Button
                          className="add-user-button acn-flex acn-flex-middle"
                          htmlType="submit">
                          <span className="edit-icon acn-flex acn-flex-middle acn-flex-center">
                            <img src={IMAGES.editIcon} alt="add" />
                          </span>
                          Edit
                        </Button>
                      ) : (
                        <div
                          className="page-title-box page-title-edit-mode acn-flex acn-flex-middle acn-flex-right"
                          hidden={!isEditMode}>
                          <Button
                            className="add-user-button acn-flex acn-flex-middle mr-5"
                            onClick={handleCancel}>
                            Cancel
                          </Button>
                          <Button
                            htmlType="submit"
                            className="add-user-button btn-fill acn-flex acn-flex-middle">
                            Save changes
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="taxonomy-overview-content-wraps">
                  <div className="taxonomy-overview-content-subHeader">Purpose</div>
                  <Form.Item name="purpose" rules={[{ required: false }]}>
                    <Input.TextArea
                      className={`input-style ${isEditMode && "edit-input-style"}`}
                      readOnly={isEditMode === false ? true : false}
                      value={getTaxonomyTree?.purpose}
                      style={{ width: "500", maxWidth: "500" }}
                    />
                  </Form.Item>
                </div>
              </div>
            </Form>
          </Card>
        </Col>

        {renderTaxonomyFooter()}
      </Row>
    </div>
  );
};

export default TaxonomyOverview;
