import React, { FC, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Card, Col, Image, Row, Skeleton } from "antd";

import { RootState } from "../../../../store";
import { IRelevantAsset } from "../../../../store/generation/kpis/kpiGeneration.interface";
import { FromWhere, handleOpenPdfDoc, Images, IMAGES, isEmpty, PATHS } from "../../../../shared";
import "./RelevantAssets.scss";

interface IRelevantAssetsProps {
  height: number;
}
const RelevantAssets: FC<IRelevantAssetsProps> = ({ height }) => {
  const navigate = useNavigate();
  const scaleStep = 0.5;
  const { isThumbnailLoading, relevantAssets } = useSelector(
    (state: RootState) => state.kpiGeneration
  );

  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");

  const resultCount = (data: number = 0) => {
    if (data === 0) {
      return "";
    } else if (data < 10) {
      return `Showing top 0${data} results`;
    } else {
      return `Showing top ${data} results`;
    }
  };

  const isImage = (item: IRelevantAsset) => Images.includes(item?.file_type || "");

  const handleOnTitleClick = (
    item: IRelevantAsset,
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    if (isImage(item)) {
      setVisible(true);
      setSelectedImageURL(item.pre_signed_url);
    } else {
      e.stopPropagation();
      if (item.file_type === "xls" || item.file_type === "xlsx") {
        toast.error("Excel previews are not supported.");
      } else {
        const page = item?.pages?.length ? item.pages[0] : 0;
        handleOpenPdfDoc(Number(item?.asset_id), item.file_name, "", item?.title, page, true);
      }
    }
  };

  return (
    <>
      <div className="relevant-assets relevant-assets-kpi">
        <div className="relevant-assets-inner">
          <h2>Relevant assets found</h2>
          <h4 className="showing-results">
            {!isEmpty(relevantAssets) ? resultCount(relevantAssets?.length) : <></>}
          </h4>

          <div className="inner-assets" style={{ height: `calc(${height}px - 95px)` }}>
            <div id="grid-view" className="grid-card-view">
              {!isEmpty(relevantAssets) ? (
                <Row gutter={[26, 14]}>
                  {relevantAssets?.map((item: IRelevantAsset, index: number) => (
                    <Col span={12} key={index}>
                      <Card className="skeleton-style cursor-auto">
                        {isThumbnailLoading ? (
                          <Skeleton.Image active style={{ width: "100%" }} />
                        ) : (
                          <div
                            onClick={() =>
                              navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                                state: {
                                  fromWhere: FromWhere.KpiGeneration,
                                },
                              })
                            }
                            className="thumbnail cursor-pointer"
                            style={{
                              backgroundImage: `url(${item?.pre_signed_url})`,
                            }}></div>
                        )}

                        <span className="card-content">
                          <h3
                            className="cursor-pointer"
                            onClick={(e) => {
                              handleOnTitleClick(item, e);
                            }}>
                            {item.title}
                          </h3>
                          <span>KPI</span>
                          <span
                            className="cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                                state: {
                                  fromWhere: FromWhere.KpiGeneration,
                                },
                              });
                            }}>
                            <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
                          </span>
                        </span>
                      </Card>
                    </Col>
                  ))}
                </Row>
              ) : (
                <div className="no-data-found">No data found</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default RelevantAssets;
