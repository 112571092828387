import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "antd";
import {
  UnsavedWarn,
  Topic,
  IndustryFun,
  Agenda,
  CompanyDetails,
} from "../../../components/generation/businessProposal";
import { RootState } from "../../../store";
import {
  IAgenda,
  IFields,
  IPPTGeneration,
} from "../../../store/generation/businessProposal/businessProposal.interface";
import {
  clearIsDisable,
  clearIsLoading,
  fieldsInitValue,
  generationFields,
  setAgendaOpt,
  setIsPPTDataLoading,
  setIsPPTPullingLoading,
  setUpdateFields,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { exportPPTDoc, fetchPPTGeneration } from "../../../services/generation";
import {
  AGENDA_OPT,
  getDefaultSelectedAgendaId,
  getIsProcessing,
} from "../../../helper/GenerationHelper";
import { AssetUploadStatus, IMAGES, isEmpty } from "../../../shared";
import "./BusinessProposal.scss";
import "../Generation.scss";

const GenerationBusinessProposalPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { fields, pptGeneration } = useSelector((state: RootState) => state.busProposalSlice);
  const [localFields, setLocalFields] = useState<IFields>(fieldsInitValue);
  let pptGenerationInterval: any;

  // console.log("localFields  ===", localFields);
  // console.log("redux fields  ===", fields);
  // console.log("pptGeneration  ===", pptGeneration);
  // console.log("######################################");

  const handleSetAgendaOpt = (agendaOptRes: IAgenda[]) => {
    if (!isEmpty(agendaOptRes)) {
      dispatch(setAgendaOpt(agendaOptRes));
    } else {
      dispatch(setAgendaOpt(AGENDA_OPT));
    }
  };

  const getSlectedAgendaId = (agendaRes: IAgenda[]) => {
    let selectedIds: any[] = [];
    const defaultSelectedAgendaId = getDefaultSelectedAgendaId()
      ? [getDefaultSelectedAgendaId()]
      : [];

    if (!isEmpty(agendaRes)) {
      agendaRes.forEach((agenda) => {
        if (agenda?.is_selected) {
          selectedIds.push(agenda?.agendaId);
        }
      });
    }

    return !isEmpty(selectedIds) ? selectedIds : defaultSelectedAgendaId;
  };

  const handleUpdateReduxField = (res: IPPTGeneration) => {
    dispatch(setUpdateFields({ field: generationFields.topic, value: res?.prompt || "" }));
    dispatch(
      setUpdateFields({ field: generationFields.industry, value: res?.taxonomy_node_id || null })
    );
    dispatch(
      setUpdateFields({
        field: generationFields.agenda,
        value: getSlectedAgendaId(res?.agendas || []),
      })
    );
    dispatch(
      setUpdateFields({
        field: generationFields.companyDetails,
        value: {
          name: res?.company_name || "",
          docName: res?.original_file_name || "",
        },
      })
    );
  };

  const getPPTGenerationData = async () => {
    dispatch(setIsPPTDataLoading(true));
    const res = await fetchPPTGeneration(pptGeneration?.business_proposal_id);

    if (!isEmpty(res)) {
      handleSetAgendaOpt(res?.agendas || []);
      handleUpdateReduxField(res);
      setLocalFields((prev) => ({
        ...prev,
        topic: res?.prompt || "",
        industry: res?.taxonomy_node_id || null,
        agenda: getSlectedAgendaId(res?.agendas || []),
        companyDetails: {
          ...prev.companyDetails,
          name: res?.company_name || "",
          docName: res?.original_file_name || "",
        },
      }));
    }
    dispatch(setIsPPTDataLoading(false));
  };

  useEffect(() => {
    dispatch(clearIsLoading());
    dispatch(clearIsDisable());
    getPPTGenerationData();
  }, []);

  const handlePPTGenerationPulling = () => {
    const pptGenerationId = pptGeneration?.business_proposal_id || null;
    const docStatus = pptGeneration?.status || "";

    if (pptGenerationId && getIsProcessing(docStatus)) {
      dispatch(setIsPPTPullingLoading(true));
      pptGenerationInterval = setInterval(async () => {
        const res = await fetchPPTGeneration(pptGeneration?.business_proposal_id);
        if (res?.status === AssetUploadStatus.COMPLETED) {
          dispatch(setIsPPTPullingLoading(false));
          await exportPPTDoc(pptGeneration?.business_proposal_id);
          // handleDownloadPPTDoc(res);
        }
      }, 5000);
    } else {
      clearInterval(pptGenerationInterval);
      dispatch(setIsPPTPullingLoading(false));
    }
  };

  useEffect(() => {
    handlePPTGenerationPulling();
    return () => {
      clearInterval(pptGenerationInterval);
    };
  }, [pptGeneration]);

  return (
    <>
      <div className="generation-page">
        <div className="container">
          <Card className="page-card-title">
            <div className="page-title-left title-back-iconWrap">
              <img src={IMAGES.backIcon} alt="back" onClick={() => navigate(-1)} />
              <h1>Generate Business Proposal</h1>
            </div>
          </Card>

          <div className="card-scroll">
            <Topic localFields={localFields} setLocalFields={setLocalFields} />

            {pptGeneration?.prompt && (
              <IndustryFun localFields={localFields} setLocalFields={setLocalFields} />
            )}

            {pptGeneration?.prompt && pptGeneration?.taxonomy_node_id && (
              <Agenda localFields={localFields} setLocalFields={setLocalFields} />
            )}

            {pptGeneration?.taxonomy_node_id && !isEmpty(pptGeneration?.agendas) && (
              <CompanyDetails localFields={localFields} setLocalFields={setLocalFields} />
            )}
            {/* <CompanyDetails localFields={localFields} setLocalFields={setLocalFields} /> */}
          </div>
        </div>
      </div>

      <UnsavedWarn localFields={localFields} fields={fields} />
    </>
  );
};

export default GenerationBusinessProposalPage;
