import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";

import {
  UnsavedWarn,
  KpiTopic,
  KpiFunctions,
  KpiProcess,
  KpiSubProcess,
  KpiExportDoc,
} from "../../../components/generation/kpiSection";
import {
  IFields,
  IKpiGenerations,
  IRelevantAsset,
} from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  fieldsInitValue,
  generationFields,
  setIsAllLoading,
  setIsKpiDataLoading,
  setKpiGenerations,
  setRelevantAssets,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { RootState } from "../../../store";
import { fetchPendingKpis } from "../../../services/generation";
import { AssetUploadStatus, IMAGES } from "../../../shared";
import {
  getIsDisable,
  getIsProcessing,
  getSelectedFunction,
  getSelectedProcess,
  getSelectedSubProcess,
  getSelectedFunKeys,
  handleSetThumbnailUrl,
  getIsFetchingDone,
  groupItemsByFileName,
} from "../../../helper/GenerationHelper";
import "./GenerateKPIs.scss";

const GenerateKPIs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let kpiInterval: any;

  const { fields, kpiGenerations } = useSelector((state: RootState) => state.kpiGeneration);
  const [localFields, setLocalFields] = useState<IFields>(fieldsInitValue);
  const [isGotRelevantAsset, setIsGotRelevantAsset] = useState<boolean>(false);

  const handleSetDisable = (res: IKpiGenerations) => {
    if (
      getIsProcessing(res?.process_status) ||
      getIsProcessing(res?.sub_process_status) ||
      getIsProcessing(res?.document_status)
    ) {
      dispatch(setUpdateIsDisable({ field: generationFields.topic, value: true }));
    } else {
      dispatch(setUpdateIsDisable({ field: generationFields.topic, value: false }));
    }

    if (res?.functions && res?.functions.length) {
      const selectedFun = res?.functions?.filter((fun) => fun.is_selected);
      if (selectedFun && selectedFun?.length) {
        dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
      }
    }

    if (getIsDisable(res?.process_status) || getIsProcessing(res?.process_status)) {
      dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
    }

    if (getIsDisable(res?.sub_process_status) || getIsProcessing(res?.sub_process_status)) {
      dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.process, value: true }));
    }

    if (getIsDisable(res?.document_status) || getIsProcessing(res?.document_status)) {
      dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.process, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.subProcess, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.exportDoc, value: true }));
    }
    if (res?.document_status === AssetUploadStatus.COMPLETED) {
      dispatch(setUpdateIsDisable({ field: generationFields.exportDoc, value: false }));
    }

    if (res?.sub_processes && res?.sub_processes.length) {
      const selectedSubProcess = res?.sub_processes?.filter((item) => item.is_selected);
      if (selectedSubProcess && selectedSubProcess?.length) {
        dispatch(setUpdateIsDisable({ field: generationFields.subProcess, value: true }));
        dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: true }));
      } else {
        dispatch(setUpdateIsDisable({ field: generationFields.subProcess, value: false }));
        dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: false }));
      }
    }
  };

  const handleSetFields = (res: IKpiGenerations) => {
    dispatch(setUpdateFields({ field: generationFields.topic, value: res?.prompt || "" }));
    dispatch(
      setUpdateFields({
        field: generationFields.functionsKeys,
        value: getSelectedFunKeys(res?.functions || []),
      })
    );
    dispatch(
      setUpdateFields({
        field: generationFields.functions,
        value: getSelectedFunction(res?.functions || []),
      })
    );
    dispatch(
      setUpdateFields({
        field: generationFields.process,
        value: getSelectedProcess(res?.processes || []),
      })
    );
    dispatch(
      setUpdateFields({
        field: generationFields.subProcess,
        value: getSelectedSubProcess(res?.sub_processes || []),
      })
    );

    // const selectedSubProcess = getSelectedSubProcess(res?.sub_processes || []);
    // if (selectedSubProcess && selectedSubProcess.length) {
    //   dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: true }));
    // }
  };

  const handleRelevantAsset = (relevantAssets: IRelevantAsset[]) => {
    const groupData = groupItemsByFileName(relevantAssets);
    dispatch(setRelevantAssets(groupData));
    handleSetThumbnailUrl(groupData);
  };

  const getPendingKpis = async () => {
    dispatch(setIsAllLoading(true));
    dispatch(setIsKpiDataLoading(true));
    const res = await fetchPendingKpis(kpiGenerations?.kpi_id);

    if (res) {
      handleSetDisable(res);
      handleSetFields(res);
      setLocalFields({
        ...localFields,
        topic: res?.prompt || "",
        functionsKeys: getSelectedFunKeys(res?.functions || []),
        functions: getSelectedFunction(res?.functions || []),
        process: getSelectedProcess(res?.processes || []),
        subProcess: getSelectedSubProcess(res?.sub_processes || []),
      });

      handleRelevantAsset(res?.relevant_assets || []);
    } else {
      dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: false }));
    }
    dispatch(setIsAllLoading(false));
    dispatch(setIsKpiDataLoading(false));
  };

  useEffect(() => {
    getPendingKpis();
  }, []);

  const handleKpiPulling = () => {
    const kpiId = kpiGenerations?.kpi_id || null;
    const functionStatus = kpiGenerations?.function_status || "";
    const processStatus = kpiGenerations?.process_status || "";
    const subProcessStatus = kpiGenerations?.sub_process_status || "";
    const documentStatus = kpiGenerations?.document_status || "";
    const relevantAssetStatus = kpiGenerations?.relevant_asset_status || "";

    if (
      kpiId &&
      (getIsProcessing(functionStatus) ||
        getIsProcessing(processStatus) ||
        getIsProcessing(subProcessStatus) ||
        getIsProcessing(documentStatus) ||
        getIsProcessing(relevantAssetStatus))
    ) {
      dispatch(setIsAllLoading(true));
      kpiInterval = setInterval(async () => {
        const res = await fetchPendingKpis(kpiGenerations?.kpi_id);

        if (res) {
          handleSetDisable(res);
          handleSetFields(res);
          dispatch(setKpiGenerations(res));

          if (getIsFetchingDone(res?.relevant_asset_status) && !isGotRelevantAsset) {
            setIsGotRelevantAsset(true);
            handleRelevantAsset(res?.relevant_assets || []);
          }
        } else {
          dispatch(setUpdateIsShow({ field: generationFields.exportDoc, value: false }));
        }
      }, 5000);
    } else {
      clearInterval(kpiInterval);
      dispatch(setIsAllLoading(false));
    }
  };

  useEffect(() => {
    handleKpiPulling();
    return () => {
      clearInterval(kpiInterval);
    };
  }, [kpiGenerations]);

  return (
    <>
      <div className="generation-page">
        <div className="container">
          <Card className="page-card-title">
            <div className="page-title-left title-back-iconWrap">
              <img src={IMAGES.backIcon} alt="back" onClick={() => navigate(-1)} />
              <h1>Generate KPIs </h1>
            </div>
          </Card>

          <div className="card-scroll">
            <KpiTopic
              localFields={localFields}
              setLocalFields={setLocalFields}
              setIsGotRelevantAsset={setIsGotRelevantAsset}
            />

            {kpiGenerations?.function_status === AssetUploadStatus.COMPLETED &&
            getIsFetchingDone(kpiGenerations?.relevant_asset_status) ? (
              <KpiFunctions localFields={localFields} setLocalFields={setLocalFields} />
            ) : (
              <></>
            )}

            {kpiGenerations?.process_status === AssetUploadStatus.COMPLETED && (
              <KpiProcess localFields={localFields} setLocalFields={setLocalFields} />
            )}

            {kpiGenerations?.sub_process_status === AssetUploadStatus.COMPLETED && (
              <KpiSubProcess localFields={localFields} setLocalFields={setLocalFields} />
            )}

            {kpiGenerations?.document_status === AssetUploadStatus.COMPLETED && <KpiExportDoc />}
          </div>
        </div>
      </div>

      <UnsavedWarn localFields={localFields} fields={fields} kpiGenerations={kpiGenerations} />
    </>
  );
};

export default GenerateKPIs;
