import { Button, Card, Col, Image, Row, Skeleton, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FromWhere,
  IMAGES,
  Images,
  PATHS,
  handleOpenPdfDoc,
  renderFileTypeIcon,
} from "../../../shared";
import { RootState } from "../../../store";
import { ICitations } from "../../../store/conversationalSearch/conversationalSearch.interface";
import { getThumbnailsByPage } from "../../../services/conversationalSearch";
import { toast } from "react-toastify";

interface IGroupedItems {
  id: number;
  title: string;
  file_name: string;
  original_file_name: string;
  page: number[];
  file_type: string;
}

const Assets = () => {
  const navigate = useNavigate();
  const { selectedChatHistory, isThumbnailLoading } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  const { messages } = selectedChatHistory;
  const citations = messages[messages?.length - 1]?.citations;
  const [isGridView, setGridView] = useState(true);
  const [listViewRecords, setListViewRecords] = useState<IGroupedItems[]>([]);
  const [citationList, setCitationList] = useState(citations);
  const [visible, setVisible] = useState(false);
  const [selectedImageURL, setSelectedImageURL] = useState<string | undefined>("");
  const [scaleStep] = useState(0.5);

  useEffect(() => {
    if (citations.length > 0) {
      const filteredCitations = citations.filter((c) => c.asset_level === "primary");
      setCitationList(filteredCitations);
      const groupedData = groupItemsByFileName(filteredCitations);
      setListViewRecords(groupedData);
    }
  }, [citations]);

  useEffect(() => {
    if (citations.length > 0 && selectedChatHistory?.id !== -1) {
      const request = citations?.map((citation) => {
        return { file_name: citation.file_name, page_no: citation?.page };
      });
      getThumbnailsByPage(selectedChatHistory.id, request);
    }
  }, [selectedChatHistory?.id]);

  const groupItemsByFileName = (items: ICitations[]): IGroupedItems[] => {
    const grouped: { [key: string]: IGroupedItems } = {};
    items.forEach((item) => {
      const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
      if (!(key in grouped)) {
        grouped[key] = {
          id: item.asset_id,
          title: item.title,
          file_name: item.file_name,
          original_file_name: item.original_file_name,
          page: [],
          file_type: item?.file_type,
        };
      }
      if (!grouped[key].page.includes(item.page)) {
        grouped[key].page.push(item.page);
      }
    });

    return Object.values(grouped);
  };

  const columns: ColumnsType<IGroupedItems> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (_, item) => (
        <>
          <span className="td-label">Title : </span>
          <strong>
            <img src={renderFileTypeIcon(item?.file_type)} alt="Image Icon" />
            {item?.title}
          </strong>
        </>
      ),
    },
    {
      title: "Reference/Page",
      dataIndex: "page",
      key: "page",
      render: (text) => (
        <>
          <span className="td-label">Reference/Page : </span>
          {text?.toString()?.replace(/,/g, ", ")}
        </>
      ),
    },
    {
      title: " ",
      key: "action",
      render: () => {
        return (
          <>
            <span className="td-label">Action </span>
            <Button
              className="action-bta"
              // onClick={() => navigate(`${PATHS.viewContentManagementByUser}/${item?.id}`)}
            >
              <img src={IMAGES.linkIcon} alt="Link Icon" className="link-icon" />
            </Button>
          </>
        );
      },
    },
  ];

  const resultCount = (data: number = 0) => {
    if (data === 0) {
      return "";
    } else if (data < 10) {
      return `Showing top 0${data} results`;
    } else {
      return `Showing top ${data} results`;
    }
  };

  const isImage = (item: ICitations) => Images.includes(item?.file_type || "");

  const handleOnTitleClick = (
    item: ICitations,
    e: React.MouseEvent<HTMLHeadingElement, MouseEvent>
  ) => {
    if (isImage(item)) {
      setVisible(true);
      setSelectedImageURL(item.pre_signed_url);
    } else {
      e.stopPropagation();
      if (item.file_type === "xls" || item.file_type === "xlsx") {
        toast.error("Excel previews are not supported.");
      } else {
        handleOpenPdfDoc(item?.asset_id, item.file_name, "", item?.title, item.page, true);
      }
    }
  };

  return (
    <>
      <div className="relevant-assets">
        <div className="relevant-assets-inner">
          <div className="grid-list-view">
            <Tooltip overlayClassName="tooltip-text" color="#fff" title={"Grid View"}>
              <Button
                type="text"
                className={isGridView ? "grid-view active-view" : "grid-view"}
                onClick={() => setGridView(true)}>
                <span className="view-icon grid-view-icon"></span>
              </Button>
            </Tooltip>
            <Tooltip overlayClassName="tooltip-text" color="#fff" title={"List View"}>
              <Button
                type="text"
                className={!isGridView ? "list-view active-view" : "list-view"}
                onClick={() => setGridView(false)}>
                <span className="view-icon list-view-icon"></span>
              </Button>
            </Tooltip>
          </div>
          <h2>Relevant assets</h2>
          <h4 className="showing-results">
            {resultCount(isGridView ? citationList?.length : listViewRecords?.length)}
          </h4>
          <div className="inner-assets">
            {isGridView ? (
              <div id="grid-view" className="grid-card-view">
                <Row gutter={[14, 26]}>
                  {!!messages.length &&
                    citationList?.map((item) => (
                      <Col span={6}>
                        <Card className="skeleton-style cursor-pointer">
                          {isThumbnailLoading ? (
                            <Skeleton.Image active style={{ width: "100%" }} />
                          ) : (
                            <div
                              onClick={() =>
                                navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                                  state: {
                                    fromWhere: FromWhere.ConversationalSearch,
                                    selectedChatHistoryId: selectedChatHistory?.id,
                                  },
                                })
                              }
                              className="thumbnail"
                              style={{
                                backgroundImage: `url(${item?.pre_signed_url})`,
                              }}></div>
                          )}
                          <span className="card-content">
                            <h3
                              onClick={(e) => {
                                handleOnTitleClick(item, e);
                              }}>
                              {item.title}
                            </h3>
                            <span>{!isImage(item) && item.page + 1}</span>
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                                  state: {
                                    fromWhere: FromWhere.ConversationalSearch,
                                    selectedChatHistoryId: selectedChatHistory?.id,
                                  },
                                });
                              }}>
                              <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
                            </span>
                            {/* )} */}
                          </span>
                        </Card>
                      </Col>
                      // <Col span={6}>
                      //   <Card className="skeleton-style">
                      //     <Link
                      //       to={`${PATHS.viewContentManagementByUser}/${item?.asset_id}`}
                      //       state={{
                      //         fromWhere: FromWhere.ConversationalSearch,
                      //         selectedChatHistoryId: selectedChatHistory?.id,
                      //       }}
                      //       type="button"
                      //       className="mr-5">
                      //       {/* <div
                      //       onClick={() =>
                      //         navigate(`${PATHS.viewContentManagementByUser}/${item?.asset_id}`, {
                      //           state: {
                      //             selectedChatHistoryId: selectedChatHistory?.id,
                      //           },
                      //         })
                      //       }
                      //       className="thumbnail"> */}
                      //       {isThumbnailLoading ? (
                      //         <Skeleton.Image active style={{ width: "100%" }} />
                      //       ) : (
                      //         <div
                      //           className="thumbnail"
                      //           style={{
                      //             backgroundImage: `url(${item?.pre_signed_url})`,
                      //           }}></div>
                      //       )}
                      //       <span className="card-content">
                      //         <h3
                      //           onClick={(e) => {
                      //             e.stopPropagation();
                      //             handleOpenPdfDoc(
                      //               item?.asset_id,
                      //               item.file_name,
                      //               "",
                      //               item?.title,
                      //               item.page
                      //             );
                      //           }}>
                      //           {item.title}
                      //         </h3>
                      //         <span>{item.page + 1}</span>
                      //         <img src={IMAGES.iconLink} alt="Icon Link" className="icon-link" />
                      //       </span>
                      //       {/* </div> */}
                      //     </Link>
                      //   </Card>
                      // </Col>
                    ))}
                </Row>
              </div>
            ) : (
              <div id="list-view" className="list-card-view">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={listViewRecords}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`${PATHS.viewContentManagementByUser}/${record?.id}`, {
                          state: {
                            fromWhere: FromWhere.ConversationalSearch,
                            selectedChatHistoryId: selectedChatHistory?.id,
                          },
                        });
                      },
                    };
                  }}></Table>
              </div>
            )}
          </div>
        </div>
      </div>
      {visible && (
        <div style={{ display: "none" }}>
          <Image
            preview={{
              visible,
              scaleStep,
              src: `${selectedImageURL}`,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default Assets;
