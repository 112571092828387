import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Table, Tooltip } from "antd";

import SkeletonTable from "./SkeletonTable";
import RelevantAssets from "./relevantAsset/RelevantAssets";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/kpis/kpiGeneration.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
  setUpdateIsShow,
} from "../../../store/generation/kpis/kpiGenerationSlice";
import { fetchKpiProcessByFunction } from "../../../services/generation";
import {
  functionsColumn,
  getFormattedFunction,
  getIsProcessing,
} from "../../../helper/GenerationHelper";
import { IMAGES } from "../../../shared";

interface IKpiFunctions {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const KpiFunctions = ({ localFields, setLocalFields }: IKpiFunctions) => {
  const dispatch = useDispatch();
  const { isDisable, isLoading, kpiGenerations, isAllLoading, isKpiDataLoading } = useSelector(
    (state: RootState) => state.kpiGeneration
  );

  const divRef = useRef<any>(null);
  const [divHeight, setDivHeight] = useState(0);
  const loading = isLoading?.functions || isAllLoading;

  const handleSelectFunctions = (keys: React.Key[], selectedRows: any[]) => {
    if (selectedRows && selectedRows?.length) {
      setLocalFields((field) => ({ ...field, functionsKeys: keys, functions: selectedRows }));
    } else {
      setLocalFields((field) => ({ ...field, functionsKeys: [], functions: [] }));
    }
  };

  const rowSelection = {
    selectedRowKeys: localFields?.functionsKeys || [],
    onChange: handleSelectFunctions,
    getCheckboxProps: () => {
      return {
        disabled: isDisable?.functions || loading,
      };
    },
  };

  const handleFunctionsNext = async () => {
    if (
      kpiGenerations?.kpi_id &&
      localFields?.functionsKeys &&
      localFields?.functionsKeys?.length
    ) {
      const res = await fetchKpiProcessByFunction(
        kpiGenerations?.kpi_id,
        localFields?.functionsKeys
      );

      if (res) {
        dispatch(
          setUpdateFields({
            field: generationFields.functionsKeys,
            value: localFields?.functionsKeys,
          })
        );
        dispatch(
          setUpdateFields({
            field: generationFields.functions,
            value: localFields?.functions,
          })
        );

        dispatch(setUpdateIsShow({ field: generationFields.process, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.tags, value: true }));
        dispatch(setUpdateIsDisable({ field: generationFields.functions, value: true }));
      }
    } else {
      toast.error("Please select functions");
    }
  };

  useEffect(() => {
    // Get the height after the component mounts
    setTimeout(() => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    }, 300);
  }, [kpiGenerations]);

  return (
    <>
      <Card className="card-item">
        <div className="card-header">
          <span className="card-number">2</span>
          <h2>
            Selected function(s)
            <Tooltip
              title="From these functions, only the processes that are relevant to your prompt will display."
              color="#fff"
              placement="topLeft"
              overlayClassName="tooltip-text tooltip-ui-new">
              <i className="ri-information-line cursor-pointer"></i>
            </Tooltip>
          </h2>
        </div>

        <div className="table-assets-view acn-flex">
          <div className="kpi-table-view" ref={divRef}>
            <div className="card-content">
              {isKpiDataLoading ? (
                <SkeletonTable />
              ) : (
                <Table
                  rowSelection={rowSelection}
                  columns={functionsColumn}
                  dataSource={getFormattedFunction(kpiGenerations?.functions || [])}
                  pagination={false}
                />
              )}
            </div>

            {!isKpiDataLoading && kpiGenerations?.functions && kpiGenerations?.functions?.length ? (
              <div className="card-footer acn-flex acn-flex-end mb-1">
                <div className="card-footer-right acn-flex acn-flex-middle">
                  {(isLoading?.functions || getIsProcessing(kpiGenerations?.process_status)) && (
                    <div className="processing-wrap">
                      <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                      Processing
                    </div>
                  )}
                  <Button
                    type="primary"
                    className="outline-btn"
                    disabled={isDisable?.functions || loading}
                    onClick={handleFunctionsNext}>
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <RelevantAssets height={divHeight} />
        </div>
      </Card>
    </>
  );
};

export default KpiFunctions;
