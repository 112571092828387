import { IConfig } from ".";

const LOCAL_HOST = ["127.0.0.1", "localhost"];
const DEV_HOST = ["dev.acnkm.accenture.com"];
const PROD_HOST = ["acnkm.accenture.com"];

const hostname = window?.location?.hostname;

let configs: IConfig = {
  BASE_URL: "https://dev-api.acnkm.accenture.com/",
  HOST: window.location.hostname,
  WEB_SOCKET_URL: "wss://dev-ai.acnkm.accenture.com/runner-gpt/",
};

if (
  LOCAL_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Localhost environment
  const SOURCE = "https://dev-api.acnkm.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
    WEB_SOCKET_URL: "wss://dev-ai.acnkm.accenture.com/runner-gpt/",
  };
} else if (
  DEV_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Development environment
  const SOURCE = "https://dev-api.acnkm.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
    WEB_SOCKET_URL: "wss://dev-ai.acnkm.accenture.com/runner-gpt/",
  };
} else if (
  PROD_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Production environment
  const SOURCE = "https://api.acnkm.accenture.com/";
  configs = {
    BASE_URL: SOURCE,
    HOST: window.location.hostname,
    WEB_SOCKET_URL: "wss://dev-ai.acnkm.accenture.com/runner-gpt/",
  };
}

export default configs;
