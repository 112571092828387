import { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, Input, Skeleton } from "antd";
import { DeleteModal } from "../../../admin/components";
import { RootState } from "../../../store";
import { IFields } from "../../../store/generation/businessProposal/businessProposal.interface";
import {
  generationFields,
  setUpdateFields,
  setUpdateIsDisable,
} from "../../../store/generation/businessProposal/businessProposalSlice";
import { exportPPTDoc, saveCompanyDetails } from "../../../services/generation";
import { getIsProcessing } from "../../../helper/GenerationHelper";
import { AssetUploadStatus, IMAGES, isEmpty } from "../../../shared";

interface ICompantDetails {
  localFields: IFields;
  setLocalFields: Dispatch<SetStateAction<IFields>>;
}

const CompantDetails = ({ localFields, setLocalFields }: ICompantDetails) => {
  const dispatch = useDispatch();
  const { isLoading, pptGeneration, isPPTPullingLoading, isPPTDataLoading, isDocDownloading } =
    useSelector((state: RootState) => state.busProposalSlice);

  const docInputRef = useRef<HTMLInputElement>(null);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);

  const loading = isLoading?.companyDetails || isPPTPullingLoading;
  const isInputFiledDisable =
    loading ||
    [String(AssetUploadStatus.COMPLETED), AssetUploadStatus.FAILED].includes(pptGeneration?.status);

  const handleUpdateReduxState = () => {
    dispatch(
      setUpdateFields({
        field: generationFields.companyDetails,
        value: localFields?.companyDetails,
      })
    );
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalFields((field) => ({
      ...field,
      companyDetails: { ...localFields?.companyDetails, name: e.target.value },
    }));
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      setLocalFields((field) => ({
        ...field,
        companyDetails: { ...localFields?.companyDetails, docName: file?.name, document: file },
      }));
    }
    e.target.value = "";
  };

  const handleClickUploadFile = () => {
    if (docInputRef) {
      docInputRef?.current?.click();
    }
  };

  const handleRemoveFile = () => {
    setLocalFields((field) => ({
      ...field,
      companyDetails: { ...localFields?.companyDetails, docName: "" },
    }));
    setIsShowDeleteModal(false);
  };

  const handleSaveCompanyDetails = async () => {
    const formData = new FormData();
    formData.append("file", localFields?.companyDetails?.document || "");

    const res = await saveCompanyDetails(
      pptGeneration?.business_proposal_id,
      localFields?.companyDetails?.name,
      formData
    );

    if (!isEmpty(res)) {
      handleUpdateReduxState();
      dispatch(setUpdateIsDisable({ field: generationFields.industryFun, value: true }));
      dispatch(setUpdateIsDisable({ field: generationFields.agenda, value: true }));
    }
  };

  const handleNext = async () => {
    if (pptGeneration?.status === AssetUploadStatus.COMPLETED) {
      await exportPPTDoc(pptGeneration?.business_proposal_id);
      // handleDownloadPPTDoc(pptGeneration);
    } else if (pptGeneration?.status === AssetUploadStatus.FAILED) {
      toast.error("Business Proposal generation failed. Please restart the generation.");
    } else {
      handleSaveCompanyDetails();
    }
  };

  return (
    <>
      <Card className="card-item card-enter-topic">
        <div className="card-header">
          <span className="card-number">4</span>
          <h2>
            Enter Company Details <span className="optional-text">(Optional)</span>
          </h2>
        </div>
        <div className="card-content ">
          <div className="generation-input position-relative file-upload-inner">
            {isPPTDataLoading ? (
              <Skeleton.Input className="skeleton-input" active={true} />
            ) : (
              <div className="file-upload-generation">
                <Input
                  type="text"
                  placeholder="Enter company name"
                  value={localFields?.companyDetails?.name ?? ""}
                  onChange={handleChangeName}
                  disabled={isInputFiledDisable}
                />

                <div className="browse-container">
                  <div className="browse-wrap">
                    <input
                      hidden
                      ref={docInputRef}
                      className="file-input"
                      type="file"
                      multiple={false}
                      placeholder="Upload Asset"
                      onChange={(e) => handleFileUpload(e)}
                      accept=".ppt, .pptx, .pdf"
                    />
                    <div className="upload-file-container">
                      <div className="file-name">
                        {localFields?.companyDetails?.docName ? (
                          <>
                            <div className="file-name-div acn-flex">
                              <i className="ri-attachment-2 browseIcon"></i>
                              <span className="file-name-span">
                                {localFields?.companyDetails?.docName}
                              </span>
                            </div>
                            <Button
                              type="text"
                              className="text-btn remove-file"
                              disabled={isInputFiledDisable}
                              onClick={() => setIsShowDeleteModal(true)}>
                              <i className="ri-delete-bin-fill"></i>
                            </Button>
                          </>
                        ) : (
                          <Button
                            className="add-user-button acn-flex acn-flex-middle plus-icon-bg-btn"
                            disabled={isInputFiledDisable}
                            onClick={() => handleClickUploadFile()}>
                            <span className="acn-flex acn-flex-middle acn-flex-center">
                              <i className="ri-attachment-2 browseIcon"></i>
                            </span>
                            Upload document
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isPPTDataLoading && (
              <div className="card-footer acn-flex acn-flex-end">
                <div className="card-footer-right acn-flex acn-flex-middle">
                  {(isLoading?.companyDetails || getIsProcessing(pptGeneration?.status)) && (
                    <div className="processing-wrap">
                      <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" />
                      Processing
                    </div>
                  )}

                  <Button
                    type="primary"
                    className="outline-btn"
                    loading={isDocDownloading}
                    disabled={loading || getIsProcessing(pptGeneration?.status) || isDocDownloading}
                    onClick={handleNext}>
                    {isDocDownloading ? "Exporting" : "Export"}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>

      {isShowDeleteModal && (
        <DeleteModal
          isLoading={false}
          isModalOpen={isShowDeleteModal}
          handleCancel={() => setIsShowDeleteModal(false)}
          handleSubmit={handleRemoveFile}
          message="Are you sure you want to delete this document?"
          title="Delete Document"
        />
      )}
    </>
  );
};

export default CompantDetails;
