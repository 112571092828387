import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import {
  IFields,
  IKpiGenerationSliceProps,
  IIsDisable,
  IIsLoading,
  IIsShow,
  IKpiGenerations,
  IProcess,
  ISubProcess,
  IRelevantAsset,
} from "./kpiGeneration.interface";
import { IThumbnails } from "../../conversationalSearch/conversationalSearch.interface";
import { isEmpty } from "../../../shared";

export const relevantAssetTestValue: IRelevantAsset[] = [
  {
    id: 35329,
    title: "BBP_Cost Center AccountingV1.3.docx",
    file_name: "46/BBPCostCenterAccountingV13docx-1718973335235.pdf",
    file_type: "docx",
    original_file_name: "BBP_Cost Center AccountingV1.3.docx",
    page: 1,
    score: 0.43265602,
    content: "",
    asset_id: "1161",
    asset_type: null,
    s_no: "133589",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35330,
    title: "S&M Digital Use Cases (1).pdf",
    file_name: "10/SMDigitalUseCases1pdf-1719896954355.pdf",
    file_type: "pdf",
    original_file_name: "S&M Digital Use Cases (1).pdf",
    page: 3,
    score: 0.429596841,
    content: "",
    asset_id: "1176",
    asset_type: null,
    s_no: "148662",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35331,
    title: "BBP_Cost Center AccountingV1.3.docx",
    file_name: "46/BBPCostCenterAccountingV13docx-1718973335235.pdf",
    file_type: "docx",
    original_file_name: "BBP_Cost Center AccountingV1.3.docx",
    page: 19,
    score: 0.428040087,
    content: "",
    asset_id: "1161",
    asset_type: null,
    s_no: "527086",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35332,
    title: "BBP_Cost Center AccountingV1.3.docx",
    file_name: "46/BBPCostCenterAccountingV13docx-1718973335235.pdf",
    file_type: "docx",
    original_file_name: "BBP_Cost Center AccountingV1.3.docx",
    page: 21,
    score: 0.427638173,
    content: "",
    asset_id: "1161",
    asset_type: null,
    s_no: "624908",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35333,
    title: "BBP_Steel_Melting_Shop.docx",
    file_name: "46/W03BBPSteelMeltingShopdocx-1718957139269.pdf",
    file_type: "docx",
    original_file_name: "W03_BBP_Steel_Melting_Shop.docx",
    page: 13,
    score: 0.427395284,
    content: "",
    asset_id: "1143",
    asset_type: null,
    s_no: "703866",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35334,
    title: "BBP_PM_Shutdown Maintenance_V0.2.doc",
    file_name: "46/BBPPMShutdownMaintenanceV02doc-1718959394482.pdf",
    file_type: "doc",
    original_file_name: "BBP_PM_Shutdown Maintenance_V0.2.doc",
    page: 11,
    score: 0.421693087,
    content: "",
    asset_id: "1144",
    asset_type: null,
    s_no: "386843",
    asset_level: "primary",
    is_editable: true,
  },
  {
    id: 35335,
    title: "S&M Digital Use Cases (1).pdf",
    file_name: "10/SMDigitalUseCases1pdf-1719896954355.pdf",
    file_type: "pdf",
    original_file_name: "S&M Digital Use Cases (1).pdf",
    page: 4,
    score: 0.420965731,
    content: "",
    asset_id: "1176",
    asset_type: null,
    s_no: "458913",
    asset_level: "primary",
    is_editable: true,
  },
];

const groupItemsByFileName = (items: IRelevantAsset[]): IRelevantAsset[] => {
  const grouped: any = {};

  items.forEach((item) => {
    const key = `${item.title}-${item.file_name}-${item.original_file_name}`;
    if (!(key in grouped)) {
      grouped[key] = {
        asset_id: item.asset_id,
        title: item.title,
        file_name: item.file_name,
        original_file_name: item.original_file_name,
        page: item.page,
        file_type: item?.file_type,
        is_editable: item?.is_editable,
        pages: [],
      };
    }
    if (!grouped[key].pages.includes(item.page)) {
      grouped[key].pages.push(item.page);
    }
  });

  return Object.values(grouped);
};

export const relevantAssetValue: IRelevantAsset[] = groupItemsByFileName(relevantAssetTestValue);

export const generationFields = {
  topic: "topic",
  tags: "tags",
  tagsKeys: "tagsKeys",
  functions: "functions",
  functionsKeys: "functionsKeys",
  process: "process",
  subProcess: "subProcess",
  subProcessKeys: "subProcessKeys",
  exportDoc: "exportDoc",
  exportDocKeys: "exportDocKeys",
};

const isShowInitValue: IIsShow = {
  topic: true,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

const isDisableInitValue: IIsDisable = {
  topic: false,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

const isLoadingInitValue: IIsLoading = {
  topic: false,
  tags: false,
  functions: false,
  process: false,
  subProcess: false,
  exportDoc: false,
};

export const fieldsInitValue: IFields = {
  topic: "",
  tags: [],
  tagsKeys: [],
  functions: [],
  functionsKeys: [],
  process: [],
  subProcess: [],
  exportDoc: [],
  exportDocKeys: [],
};

export const kpiGenerationsInitValue: IKpiGenerations = {
  kpi_id: null,
  prompt: "",
  document_name: null,
  document_pre_signed_url: null,

  tags: [],
  functions: [],
  processes: [],
  sub_processes: [],

  tags_status: "",
  function_status: "",
  process_status: "",
  sub_process_status: "",
  document_status: "",
  relevant_asset_status: "",
};

const initialState: IKpiGenerationSliceProps = {
  isShow: isShowInitValue,
  isDisable: isDisableInitValue,
  isLoading: isLoadingInitValue,
  fields: fieldsInitValue,
  processList: [],
  subProcessList: [],
  isAllLoading: true,
  isKpiDataLoading: false,
  kpiGenerations: kpiGenerationsInitValue,
  isGetRelevantAssetLoading: false,
  isSaveRelevantAssetLoading: false,
  isThumbnailLoading: false,
  relevantAssets: [],
};

export const kpiGenerationSlice = createSlice({
  name: "kpiGenerationSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setUpdateIsShow: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isShow = {
        ...state.isShow,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsShow: (state) => {
      state.isShow = isShowInitValue;
    },

    setUpdateIsDisable: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isDisable = {
        ...state.isDisable,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsDisable: (state) => {
      state.isDisable = isDisableInitValue;
    },

    setUpdateIsLoading: (state, action: PayloadAction<{ field: string; value: boolean }>) => {
      state.isLoading = {
        ...state.isLoading,
        [action.payload.field]: action.payload.value,
      };
    },
    clearIsLoading: (state) => {
      state.isLoading = isLoadingInitValue;
    },

    setUpdateFields: (
      state,
      action: PayloadAction<{ field: string; value: string | React.Key[] | any[] }>
    ) => {
      state.fields = {
        ...state.fields,
        [action.payload.field]: action.payload.value,
      };
    },
    clearFields: (state) => {
      state.fields = fieldsInitValue;
    },

    clearAllData: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = fieldsInitValue;
      state.kpiGenerations = kpiGenerationsInitValue;
    },
    clearDataOnTopicStart: (state) => {
      state.isShow = isShowInitValue;
      state.isDisable = isDisableInitValue;
      state.isLoading = isLoadingInitValue;
      state.fields = { ...fieldsInitValue, topic: state?.fields?.topic };
      state.kpiGenerations = {
        ...kpiGenerationsInitValue,
        kpi_id: state?.kpiGenerations?.kpi_id,
        prompt: state?.kpiGenerations?.prompt,
      };
      state.relevantAssets = [];
    },

    setProcessList: (state, action: PayloadAction<IProcess[]>) => {
      state.processList = action.payload;
    },
    setSubProcessList: (state, action: PayloadAction<ISubProcess[]>) => {
      state.subProcessList = action.payload;
    },

    setIsAllLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllLoading = action.payload;
    },
    setIsKpiDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isKpiDataLoading = action.payload;
    },
    setKpiGenerations: (state, action: PayloadAction<any>) => {
      state.kpiGenerations = action.payload;
    },

    setRelevantAssets: (state, action: PayloadAction<IRelevantAsset[]>) => {
      state.relevantAssets = action.payload;
    },

    setThumbnailInAsset: (state, action: PayloadAction<IThumbnails[]>) => {
      let data: IRelevantAsset[] = [];
      if (!isEmpty(state?.relevantAssets)) {
        state?.relevantAssets?.forEach((asset, index) => {
          data.push({ ...asset, pre_signed_url: action.payload[index]?.pre_signed_url || "" });
        });
      }
      state.relevantAssets = data;
    },

    setIsGetRelevantAssetLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetRelevantAssetLoading = action.payload;
    },
    setIsSaveRelevantAssetLoading: (state, action: PayloadAction<boolean>) => {
      state.isSaveRelevantAssetLoading = action.payload;
    },
    setIsThumbnailLoading: (state, action: PayloadAction<boolean>) => {
      state.isThumbnailLoading = action.payload;
    },
  },
});

export const {
  setUpdateIsShow,
  clearIsShow,
  setUpdateIsDisable,
  clearIsDisable,
  setUpdateIsLoading,
  clearIsLoading,
  setUpdateFields,
  clearFields,
  clearAllData,
  clearDataOnTopicStart,
  setProcessList,
  setSubProcessList,
  setIsAllLoading,
  setIsKpiDataLoading,
  setKpiGenerations,
  setRelevantAssets,
  setThumbnailInAsset,
  setIsGetRelevantAssetLoading,
  setIsSaveRelevantAssetLoading,
  setIsThumbnailLoading,
} = kpiGenerationSlice.actions;

export default kpiGenerationSlice.reducer;
