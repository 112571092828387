import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useBlocker } from "react-router-dom";
import { DeleteModal } from "../../../admin/components";
import { clearAllDataBusProp } from "../../../store/generation/businessProposal/businessProposalSlice";
import { IFields } from "../../../store/generation/businessProposal/businessProposal.interface";

interface IUnsavedWarn {
  localFields: IFields;
  fields: IFields;
}

const UnsavedWarn = ({ localFields, fields }: IUnsavedWarn) => {
  const dispatch = useDispatch();
  const [isShowUnsavedChangesModal, setIsShowUnsavedChangesModal] = useState<boolean>(false);

  const getIsAnyFieldChanged = () => {
    const prevTopic = fields?.topic?.trim() || "";
    const prevIndustry = fields?.industry || null;
    const prevAgendaIds = (fields?.agenda || []).slice().sort((a: any, b: any) => a - b);
    const prevCompanyName = fields?.companyDetails?.name?.trim() || "";
    const prevDocName = fields?.companyDetails?.docName?.trim() || "";

    const currTopic = localFields?.topic?.trim() || "";
    const currIndustry = localFields?.industry || null;
    const currAgendaIds = (localFields?.agenda || []).slice().sort((a: any, b: any) => a - b);
    const currCompanyName = localFields?.companyDetails?.name?.trim() || "";
    const currDocName = localFields?.companyDetails?.docName?.trim() || "";

    return (
      prevTopic !== currTopic ||
      prevIndustry !== currIndustry ||
      JSON.stringify(prevAgendaIds) !== JSON.stringify(currAgendaIds) ||
      prevCompanyName !== currCompanyName ||
      prevDocName !== currDocName
    );
  };

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && getIsAnyFieldChanged()
  );

  const handlecancelUnsavedChanges = () => {
    setIsShowUnsavedChangesModal(false);
    blocker.state === "blocked" && blocker.reset();
  };

  const handleProceedUnsavedChanges = () => {
    if (blocker.state === "blocked") {
      dispatch(clearAllDataBusProp());
      blocker.proceed();
    }
  };

  useEffect(() => {
    if (blocker?.state === "blocked") {
      setIsShowUnsavedChangesModal(true);
    }
  }, [blocker]);

  return (
    <>
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowUnsavedChangesModal}
        handleCancel={handlecancelUnsavedChanges}
        handleSubmit={handleProceedUnsavedChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to leave?"
        submitBtnName="Proceed"
      />
    </>
  );
};

export default UnsavedWarn;
